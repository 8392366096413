import ApiService from "@/common/api.service";
import JwtService from "@/common/jwt.service";
// import router from "@/router";

const state = {
  errors: null,
  message: null,
  psikotestIntroduction: null
};

const getters = {
  getPsikotestIntroduction(state) {
    return state.psikotestIntroduction;
  }
};

const actions = {
  async getPsikotestIntroduction() {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      const response = await ApiService.get("api/psikotest-introduction/show");
      return response.data;
    }
  },
  async storePsikotestIntroduction(context, psikotestIntroduction) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      const response = await ApiService.post(
        "api/psikotest-introduction/store",
        {
          title: psikotestIntroduction.title,
          rules: psikotestIntroduction.rules
        }
      );

      if (psikotestIntroduction.file.name != null) {
        let bannerImage = JSON.parse(
          response.data.psikotestIntroduction.bannerImages
        );
        if (psikotestIntroduction.file.name != bannerImage.file_name) {
          ApiService.setHeaderMultipartFormData();
          let formData = new FormData();
          formData.append("file", psikotestIntroduction.file);
          const response_image = await ApiService.post(
            "api/psikotest-introduction/upload-image",
            formData
          );
          if (response_image.status == 200) {
            return response_image;
          }
        }
      }

      return response;
    }
  }
};

const mutations = {
  setPsikotestIntroduction(state, psikotestIntroduction) {
    state.psikotestIntroduction = psikotestIntroduction;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
