import ApiService from '@/common/api.service'

const newAccess = {
  namespaced: true,
  state: () => ({
    errors: null,
    newAccess: null,
    isLoading: false,
  }),
  getters: {
    // Fot get new access
    getNewAccess(state) {
      return state.newAccess
    },
  },
  actions: {
    // For new access
    fetchAll(context) {
      return new Promise((resolve, reject) => {
        ApiService.init()
        ApiService.setHeaderMultipartFormData()
        context.commit('setLoading', true)
        ApiService.get(`/api/v2/access/all`).then(
          response => {
            if (response.status === 200) {
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          error => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    fetchAccess(context, content) {
      return new Promise((resolve, reject) => {
        ApiService.init()
        ApiService.setHeaderMultipartFormData()
        context.commit('setLoading', true)
        ApiService.get(`/api/v2/access`, {
          perPage: content.perPage,
          page: content.page,
          sortField: content.sortField,
          sortOrder: content.sortOrder,
          search: content.search,
        }).then(
          response => {
            if (response.status === 200) {
              context.commit('setNewAccess', response.data)
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          error => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },
    // For create access
    createAccess(context, form) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)
        ApiService.init()
        ApiService.setHeaderMultipartFormData()
        ApiService.post(`/api/v2/access`, form, true).then(
          response => {
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
      })
    },
    // For update access
    updateAccess(context, form) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)
        ApiService.init()
        ApiService.setHeaderMultipartFormData()
        ApiService.put(`/api/v2/access/${form.id}/update`, form, true).then(
          response => {
            context.commit('setLoading', false)
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
      })
    },
    // For delete category
    deleteAccess(context, id) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)
        ApiService.init()
        ApiService.setHeaderMultipartFormData()
        ApiService.delete(`/api/v2/access/${id}/delete`, {}, true).then(
          response => {
            context.commit('setLoading', false)
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
      })
    },
  },
  mutations: {
    // For set loading
    setLoading(state, data) {
      state.isLoading = data
    },

    // For set new categories
    setNewAccess(state, data) {
      state.newAccess = data
    },
  },
}

export default newAccess
