import ApiService from '@/common/api.service'

const questionStorage = {
  namespaced: true,
  state: () => ({
    errors: null,
    questionStorages: null,
    deleteQuestionResponse: null,
    questionDataResponse: null,
    isLoading: false,
  }),
  getters: {
    // Fot get question storages
    getQuestionStorages(state) {
      return state.questionStorages
    },
    getDeleteQuestionResponse(state) {
      return state.deleteQuestionResponse
    },
    getQuestionDataResponse(state) {
      return state.questionDataResponse
    },
  },
  actions: {
    // For question storages
    fetchQuestionStorages(context, content) {
      return new Promise((resolve, reject) => {
        ApiService.init()
        ApiService.setHeaderMultipartFormData()
        context.commit('setLoading', true)
        ApiService.get(`/api/v2/question-storage`, {
          perPage: content.perPage,
          page: content.page,
          sortField: content.sortField,
          sortOrder: content.sortOrder,
          search: content.search,
          status: content.status,
          category: content.category,
          questionType: content.questionType,
          level: content.level,
        }).then(
          response => {
            if (response.status === 200) {
              context.commit('setQuestionStorages', response.data)
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          error => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    // For fetch question storage by id
    fetchQuestionStorage(context, id) {
      return new Promise((resolve, reject) => {
        ApiService.init()
        ApiService.setHeaderMultipartFormData()
        ApiService.get(`/api/v2/question-storage/${id}/show`, {}).then(
          response => {
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
      })
    },

    // For create question storage
    createQuestionStorage(context, form) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)
        ApiService.init()
        ApiService.setHeaderMultipartFormData()
        ApiService.post(`/api/v2/question-storage`, form, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).then(
          response => {
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
      })
    },

    // For update question storage
    updateQuestionStorage(context, form) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)
        ApiService.init()
        ApiService.setHeaderMultipartFormData()
        ApiService.post(
          `/api/v2/question-storage/${form.id}/update`,
          form.formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        ).then(
          response => {
            context.commit('setLoading', false)
            context.commit('setQuestionDataResponse', response.data)
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
      })
    },
    // For duplicate assessment
    duplicateQuestionStorage(context, id) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)
        ApiService.init()
        ApiService.setHeaderMultipartFormData()
        ApiService.post(
          `/api/v2/question-storage/${id}/duplicate`,
          {},
          true
        ).then(
          response => {
            context.commit('setLoading', false)
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
      })
    },
    // For restore question storage
    restoreQuestionStorage(context, id) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)
        ApiService.init()
        ApiService.setHeaderMultipartFormData()
        ApiService.post(
          `/api/v2/question-storage/${id}/restore`,
          {},
          true
        ).then(
          response => {
            context.commit('setLoading', false)
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
      })
    },

    // For inactive question storage
    inactiveQuestionStorage(context, id) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)
        ApiService.init()
        ApiService.setHeaderMultipartFormData()
        ApiService.delete(
          `/api/v2/question-storage/${id}/inactive`,
          {},
          true
        ).then(
          response => {
            context.commit('setLoading', false)
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
      })
    },

    // For active question storage
    activeQuestionStorage(context, id) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)
        ApiService.init()
        ApiService.setHeaderMultipartFormData()
        ApiService.delete(
          `/api/v2/question-storage/${id}/active`,
          {},
          true
        ).then(
          response => {
            context.commit('setLoading', false)
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
      })
    },

    // For delete question storage
    deleteQuestionStorage(context, id) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)
        ApiService.init()
        ApiService.setHeaderMultipartFormData()
        ApiService.delete(
          `/api/v2/question-storage/${id}/delete`,
          {},
          true
        ).then(
          response => {
            context.commit('setLoading', false)
            context.commit('setDeleteResponse', response.data)
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
      })
    },

    deleteQuestionStorageAnswer(context, id) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)
        ApiService.init()
        ApiService.setHeaderMultipartFormData()
        ApiService.delete(
          `/api/v2/question-storage/answer/${id}/delete`,
          {},
          true
        ).then(
          response => {
            context.commit('setLoading', false)
            context.commit('setQuestionDataResponse', response.data)
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
      })
    },

    deleteQuestionStorageAnswerMatrix(context, id) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)
        ApiService.init()
        ApiService.setHeaderMultipartFormData()
        ApiService.delete(
          `/api/v2/question-storage/answer/matrix/${id}/delete`,
          {},
          true
        ).then(
          response => {
            context.commit('setLoading', false)
            context.commit('setQuestionDataResponse', response.data)
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
      })
    },

    deleteQuestionStorageFile(context, id) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)
        ApiService.init()
        ApiService.setHeaderMultipartFormData()
        ApiService.delete(
          `/api/v2/question-storage/file/${id}/delete`,
          {},
          true
        ).then(
          response => {
            context.commit('setLoading', false)
            context.commit('setQuestionDataResponse', response.data)
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
      })
    },

    filterQuestionStorageByName(context, content) {
      return new Promise((resolve, reject) => {
        ApiService.init()
        ApiService.get(
          `/api/v2/question-storage/filter-question-storage-by-name`,
          {
            search: content.search,
            category: content.category,
          }
        ).then(
          response => {
            if (response.status === 200) {
              resolve(response)
              return response
            }
          },
          error => {
            reject(error)
          }
        )
      })
    },
  },
  mutations: {
    // For set loading
    setLoading(state, data) {
      state.isLoading = data
    },

    // For set new categories
    setQuestionStorages(state, data) {
      state.questionStorages = data
    },

    setDeleteResponse(state, data) {
      state.deleteQuestionResponse = data
    },

    setQuestionDataResponse(state, data) {
      state.questionDataResponse = data
    },
  },
}

export default questionStorage
