import ApiService from "@/common/api.service";
import JwtService from "@/common/jwt.service";
import router from "@/router";

// const namespaced = true;
const state = {
  errors: null,
  message: null,
  users: null,
  user: null,
  surveyors: null,
  surveyorRespondent: null
};

const getters = {
  getUsers(state) {
    return state.users;
  },
  getUser(state) {
    return state.user;
  },
  getSurveyors(state) {
    return state.surveyors;
  },
  getSurveyor(state) {
    return state.surveyor;
  },
  getSurveyorRespondent(state) {
    return state.surveyorRespondent;
  }
};

const actions = {
  async getUsers(context, credentials) {
    ApiService.setHeader();
    const response = await ApiService.get("api/user/index", {
      perPage: credentials.perPage,
      page: credentials.page,
      orderBy: credentials.sortField,
      orderDirection: credentials.sortOrder,
      search: credentials.search
    });
    context.commit("setUsers", response.data);
  },
  async getUser(context, id) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      const response = await ApiService.get("api/user/" + id + "/show");
      return response.data;
    }
  },
  async deleteUser(context, credentials) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      const response = await ApiService.post(
        "api/user/" + credentials.id + "/destroy"
      );
      if (response.status == 200) {
        context.commit("deleteUser", credentials.index);
      }
    }
  },
  async updateUser(context, user) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      await ApiService.post("api/user/" + user.id + "/update", user);
    }
  },
  async updatePassword(context, user) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      const response = await ApiService.post(
        "api/user/" + user.id + "/update-password",
        {
          old_password: user.oldPassword,
          new_password: user.password
        }
      );
      if (response.status == 200) {
        router.push({ name: "user" });
      }
    }
  },
  async surveyorSurveyDetail(context, content) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      const response = await ApiService.get(
        "api/surveyor/" + content.id + "/surveyor-detail-survey",
        {
          perPage: content.perPage,
          page: content.page,
          orderBy: content.sortField,
          orderDirection: content.sortOrder,
          search: content.search,
          surveyId: content.surveyId
        }
      );
      return response.data;
    }
  },
  async getSurveyors(context, surveyor) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      const response = await ApiService.get("api/surveyor/index", {
        perPage: surveyor.perPage,
        page: surveyor.page,
        orderBy: surveyor.sortField,
        orderDirection: surveyor.sortOrder,
        search: surveyor.search
      });
      context.commit("setSurveyors", response.data);
    }
  },
  async storeSurveyor(context, surveyor) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post("api/surveyor/store", {
        userId: surveyor.userId,
        name: surveyor.name,
        branch: surveyor.branch,
        phoneNumber: surveyor.phoneNumber,
        address: surveyor.address,
        birthday: surveyor.birthday,
        password: surveyor.password,
        password_confirmation: surveyor.passwordConfirmation,
        email: surveyor.email
      });
    }
  },
  async searchBranch(context, branch) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      const response = await ApiService.get("api/surveyor/getBranch", {
        search: branch
      });
      return response.data;
    }
  },
  async getSurveyor(context, id) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      const response = await ApiService.get("api/surveyor/" + id + "/show");
      return response.data;
    }
  },
  async getRespondentSurveyor(context, content) {
    ApiService.setHeader();
    const response = await ApiService.get(
      "api/surveyor/" + content.id + "/responden",
      {
        perPage: content.perPage,
        page: content.page,
        orderBy: content.sortField,
        orderDirection: content.sortOrder,
        search: content.search
      }
    );
    if (response.status == 200) {
      context.commit("setSurveyorRespondent", response.data);
    }
  },
  async updateSurveyor(context, surveyor) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      await ApiService.post(
        "api/surveyor/" + surveyor.id + "/update",
        surveyor
      );
    }
  },
  async deleteSurveyor(context, surveyor) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      const response = await ApiService.post(
        "api/surveyor/" + surveyor.id + "/destroy"
      );
      if (response.data.status == 200) {
        context.commit("deleteSurveyor", surveyor.index);
        return response.data.status;
      } else {
        return response.data.status;
      }
    }
  }
};
const mutations = {
  setUsers(state, users) {
    state.users = users;
  },
  setUser(state, user) {
    state.user = user;
  },
  deleteUser(state, id) {
    state.users.data.splice(id, id);
  },
  setSurveyors(state, surveyors) {
    state.surveyors = surveyors;
  },
  setSurveyor(state, surveyor) {
    state.surveyor = surveyor;
  },
  setSurveyorRespondent(state, surveyorRespondent) {
    state.surveyorRespondent = surveyorRespondent;
  },
  deleteSurveyor(state, id) {
    if (id == 0) {
      state.surveyors.data.splice(id, id + 1);
    } else {
      state.surveyors.data.splice(id, id);
    }
  }
};
export default {
  //   namespaced,
  state,
  actions,
  mutations,
  getters
};
