import ApiService from '@/common/api.service'

const newRole = {
  namespaced: true,
  state: () => ({
    errors: null,
    newRole: null,
    isLoading: false,
  }),
  getters: {
    // Fot get new role
    getNewRole(state) {
      return state.newRole
    },
  },
  actions: {
    fetchAll(context) {
      return new Promise((resolve, reject) => {
        ApiService.init()
        ApiService.setHeaderMultipartFormData()
        context.commit('setLoading', true)
        ApiService.get(`/api/v2/roles/all`).then(
          response => {
            if (response.status === 200) {
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          error => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },
    // For new role
    fetchRole(context, content) {
      return new Promise((resolve, reject) => {
        ApiService.init()
        ApiService.setHeaderMultipartFormData()
        context.commit('setLoading', true)
        ApiService.get(`/api/v2/roles`, {
          perPage: content.perPage,
          page: content.page,
          sortField: content.sortField,
          sortOrder: content.sortOrder,
          search: content.search,
        }).then(
          response => {
            if (response.status === 200) {
              context.commit('setNewRole', response.data)
              context.commit('setLoading', false)
              resolve(response.data)
            }
          },
          error => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },
    // For create role
    createRole(context, form) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)
        ApiService.init()
        ApiService.setHeaderMultipartFormData()
        ApiService.post(`/api/v2/roles`, form, true).then(
          response => {
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
      })
    },
    // For update role
    updateRole(context, form) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)
        ApiService.init()
        ApiService.setHeaderMultipartFormData()
        ApiService.put(`/api/v2/roles/${form.id}/update`, form, true).then(
          response => {
            context.commit('setLoading', false)
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
      })
    },
    // For delete category
    deleteRole(context, id) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)
        ApiService.init()
        ApiService.setHeaderMultipartFormData()
        ApiService.delete(`/api/v2/roles/${id}/delete`, {}, true).then(
          response => {
            context.commit('setLoading', false)
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
      })
    },
  },
  mutations: {
    // For set loading
    setLoading(state, data) {
      state.isLoading = data
    },

    // For set new categories
    setNewRole(state, data) {
      state.newRole = data
    },
  },
}

export default newRole
