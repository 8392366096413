import ApiService from '@/common/api.service'

const newTag = {
  namespaced: true,
  state: () => ({
    errors: null,
    newLevels: null,
    isLoading: false,
  }),
  getters: {
    // Fot get new levels
    getNewLevels(state) {
      return state.newLevels
    },
  },
  actions: {
    // For new levels
    async fetchLevels(context) {
      return new Promise((resolve, reject) => {
        ApiService.init()
        ApiService.setHeaderMultipartFormData()
        context.commit('setLoading', true)
        ApiService.get(`/api/v2/level`, {}).then(
          response => {
            if (response.status === 200) {
              context.commit('setNewLevels', response.data)
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          error => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    // For Get Level
    getLevels(context) {
      return new Promise((resolve, reject) => {
        ApiService.init()
        ApiService.setHeaderMultipartFormData()
        context.commit('setLoading', true)
        ApiService.get(`/api/v2/level`, {}).then(
          response => {
            if (response.status === 200) {
              resolve(response.data)
            }
          },
          error => {
            reject(error)
          }
        )
      })
    },

    // For update level rank using draggable
    updateRankLevels(context, array) {
      return new Promise((resolve, reject) => {
        ApiService.init()
        ApiService.setHeaderMultipartFormData()
        ApiService.post(`/api/v2/level/update/rank`, { array }).then(
          response => {
            if (response.status === 200) {
              context.commit('setNewLevels', response.data)
              resolve(response)
            }
          },
          error => {
            reject(error)
          }
        )
      })
    },

    // For fetch level by id
    fetchLevel(context, id) {
      return new Promise((resolve, reject) => {
        ApiService.init()
        ApiService.setHeaderMultipartFormData()
        ApiService.get(`/api/v2/level/${id}/show`, {}).then(
          response => {
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
      })
    },

    // For create level
    createLevel(context, form) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)
        ApiService.init()
        ApiService.setHeaderMultipartFormData()
        ApiService.post(`/api/v2/level`, form, true).then(
          response => {
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
      })
    },

    // For update level
    updateLevel(context, form) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)
        ApiService.init()
        ApiService.setHeaderMultipartFormData()
        ApiService.put(`/api/v2/level/${form.id}/update`, form, true).then(
          response => {
            context.commit('setLoading', false)
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
      })
    },

    // For delete level
    deleteLevel(context, id) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)
        ApiService.init()
        ApiService.setHeaderMultipartFormData()
        ApiService.delete(`/api/v2/level/${id}/delete`, {}, true).then(
          response => {
            context.commit('setLoading', false)
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
      })
    },

    // For get all level
    getAllLevel() {
      return new Promise((resolve, reject) => {
        ApiService.init()
        ApiService.get(`/api/v2/level/get-all-level`, {}).then(
          response => {
            if (response.status === 200) {
              resolve(response)
              return response
            }
          },
          error => {
            reject(error)
          }
        )
      })
    },
  },
  mutations: {
    // For set loading
    setLoading(state, data) {
      state.isLoading = data
    },

    // For set new levels
    setNewLevels(state, data) {
      state.newLevels = data
    },
  },
}

export default newTag
