import ApiService from "@/common/api.service";
import JwtService from "@/common/jwt.service";
import axios from "axios";

// import router from "@/router";

const state = {
  errors: null,
  message: null,
  caseStudies: null,
  caseStudyReports: null
};
const getters = {
  getCaseStudies(state) {
    return state.caseStudies;
  },
  getCaseStudyReports(state) {
    return state.caseStudyReports;
  }
};

const actions = {
  // For get assessment data in index
  async getCaseStudies(context, content) {
    ApiService.setHeader();
    const response = await ApiService.get("api/case-study/index", {
      perPage: content.perPage,
      page: content.page,
      orderBy: content.sortField,
      orderDirection: content.sortOrder,
      search: content.search,
      status: content.status
    });
    context.commit("setCaseStudy", response.data);
  },

  // FOr get detail case study
  async getCaseStudy(context, id) {
    ApiService.setHeader();
    const response = await ApiService.get("api/case-study/" + id + "/show");
    return response.data;
  },

  // For create case study
  async createCaseStudy(context, content) {
    ApiService.setHeader();
    const response = await ApiService.post("api/case-study/store", {
      // Information
      pageTitle: content.pageTitle,
      pageDescription: content.pageDescription,
      category: content.category,
      status: content.status,

      // Thank You Page
      thankYouPageTitle: content.thankYouPageTitle,
      thankYouPageDescription: content.thankYouPageDescription
    });
    return response;
  },

  // For edit case study
  async editCaseStudy(context, content) {
    ApiService.setHeader();
    const response = await ApiService.post(
      "api/case-study/" + content.id + "/edit",
      {
        // Information
        pageTitle: content.pageTitle,
        pageDescription: content.pageDescription,
        category: content.category,
        status: content.status,

        // Thank You Page
        thankYouPageTitle: content.thankYouPageTitle,
        thankYouPageDescription: content.thankYouPageDescription
      }
    );
    return response;
  },

  // For update case study
  async updateCaseStudySection(context, content) {
    ApiService.setHeader();
    const response = await ApiService.post(
      "api/case-study/" + content.id + "/edit-section",
      {
        introduction: content.introduction,
        rules: content.rules,
        case_study_introduction_test: content.caseStudyIntroductionTest,
        case_study_question_test: content.caseStudyQuestionTest
      }
    );
    return response.data;
  },

  // For create case study section
  async createCaseStudySection(context, content) {
    ApiService.setHeader();
    const response = await ApiService.post(
      "api/case-study/" + content.id + "/store",
      {
        introduction: content.introduction,
        rules: content.rules,
        case_study_introduction_test: content.caseStudyIntroductionTest,
        case_study_question_test: content.caseStudyQuestionTest
      }
    );
    return response.data;
  },

  // For get case study section
  async getCaseStudySection(context, id) {
    ApiService.setHeader();
    const response = await ApiService.get(
      "api/case-study/" + id + "/show-section"
    );
    return response.data;
  },

  // For get case study test
  async getCaseStudyTest(context, content) {
    ApiService.setClientHeader();
    const response = await ApiService.get(
      "api/case-study/" + content.caseStudyId + "/show-test",
      {
        nit: content.nit,
        email: content.email
      }
    );
    return response.data;
  },

  // For active case study
  async activeCaseStudy(context, content) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      const response = await ApiService.post(
        "api/case-study/" + content.id + "/active"
      );
      if (response.status == 200) {
        if (content.status != "All") {
          context.commit("deleteCaseStudy", content.index);
          if (this.getters.getCaseStudies.total > -1) {
            this.getters.getCaseStudies.total -= 1;
            this.getters.getCaseStudies.to -= 1;
          }
          if (this.getters.getCaseStudies.total == 0) {
            this.getters.getCaseStudies.from = 0;
          }
        } else {
          context.commit("changeStatusCaseStudy", content.index);
        }
      }
    }
  },

  // For inactive case study
  async inactiveCaseStudy(context, content) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      const response = await ApiService.post(
        "api/case-study/" + content.id + "/inactive"
      );
      if (response.status == 200) {
        if (content.status != "All") {
          context.commit("deleteCaseStudy", content.index);
          if (this.getters.getCaseStudies.total > -1) {
            this.getters.getCaseStudies.total -= 1;
            this.getters.getCaseStudies.to -= 1;
          }
          if (this.getters.getCaseStudies.total == 0) {
            this.getters.getCaseStudies.from = 0;
          }
        } else {
          context.commit("changeStatusCaseStudy", content.index);
        }
      }
    }
  },

  // For delete case study
  async deleteCaseStudy(context, content) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      const response = await ApiService.post(
        "api/case-study/" + content.id + "/delete"
      );
      if (response.status == 200) {
        context.commit("deleteCaseStudy", content.index);
        if (this.getters.getCaseStudies.total > -1) {
          this.getters.getCaseStudies.total -= 1;
          this.getters.getCaseStudies.to -= 1;
        }
        if (this.getters.getCaseStudies.total == 0) {
          this.getters.getCaseStudies.from = 0;
        }
      }
    }
  },

  // For duplicate case study
  async duplicateCaseStudy(context, content) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      const response = await ApiService.post(
        "api/case-study/" + content.id + "/duplicate"
      );
      if (response.status == 200) {
        context.commit("duplicateCaseStudy", response.data);
        this.getters.getCaseStudies.total += 1;
      }
    }
  },

  // For auto save case study when candidat answer
  async autoSaveCaseStudy(context, content) {
    // if (JwtService.getToken()) {
    ApiService.setHeader();
    const response = await ApiService.post("api/case-study/auto-save", {
      nit: content.nit,
      case_study_id: content.caseStudyId,
      answer: content.answer,
      app_url: content.app_url,
      id_tes: content.id_tes,
      time: content.time,
      active_step: content.activeStep,
      uid: content.uid,
      cid: content.cid,
      email: content.email,
      name: content.name,
      finish: content.finish
    });
    return response.data;
    // }
  },

  // For store hidden screen capture case study
  async storeHiddenScreenCaptureCaseStudy(context, content) {
    ApiService.setHeader();
    ApiService.setHeaderMultipartFormData();
    if (content.nit !== undefined) {
      let formData = new FormData();
      formData.append("image", content.image);
      formData.append("nit", content.nit);
      formData.append("case_study_id", content.caseStudyId);

      const response = await ApiService.post(
        "api/case-study/store-hidden-screen-capture",
        formData
      );
      return response.data;
    } else {
      return null;
    }
  },

  // For send candidat first photo
  async sendCandidatPhoto(context, content) {
    ApiService.setHeader();
    ApiService.setHeaderMultipartFormData();
    if (content.nit !== undefined) {
      let formData = new FormData();
      formData.append("image", content.image);
      formData.append("nit", content.nit);
      formData.append("case_study_id", content.caseStudyId);

      const response = await ApiService.post(
        "api/case-study/candidat-photo",
        formData
      );
      return response.data;
    }
  },

  // For store hidden photo case study
  async storeHiddenPhotoCaseStudy(context, content) {
    ApiService.setHeader();
    ApiService.setHeaderMultipartFormData();
    if (content.nit !== undefined) {
      let formData = new FormData();
      formData.append("image", content.image);
      formData.append("nit", content.nit);
      formData.append("case_study_id", content.caseStudyId);

      const response = await ApiService.post(
        "api/case-study/store-hidden-photo",
        formData
      );
      return response.data;
    } else {
      return null;
    }
  },

  // For get case study report
  async getCaseStudyReports(context, content) {
    ApiService.setHeader();
    const response = await ApiService.get("api/report-case-study/index", {
      perPage: content.perPage,
      page: content.page,
      orderBy: content.sortField,
      orderDirection: content.sortOrder,
      search: content.search,
      filter: content.filter
    });
    context.commit("setCaseStudyReports", response.data);
  },

  // For report case study
  async getReportCaseStudy(context, id) {
    ApiService.setHeader();
    const response = await ApiService.get(
      "api/report-case-study/" + id + "/show"
    );
    return response.data;
  },

  // For get proctoring case study
  async getProctoringCaseStudy(context, content) {
    ApiService.setHeader();
    const response = await ApiService.get(
      "api/report-case-study/detail-proctoring",
      {
        nit: content.nit,
        case_study_id: content.caseStudyId,
        perPage: content.perPage,
        page: content.page,
        orderBy: content.sortField,
        orderDirection: content.sortOrder
      }
    );
    return response.data;
  },

  // For download image of case study
  async downloadImageCaseStudy(context, content) {
    var resultCode = null;
    await axios({
      url:
        process.env.VUE_APP_BACKEND_URL +
        "/api/report-case-study/download-image",
      params: {
        date: content.date,
        image: content.image,
        type: content.type,
        nit: content.nit,
        case_study_id: content.caseStudyId
      },
      method: "GET",
      headers: {
        Authorization: `Bearer ${JwtService.getToken()}`
      },
      responseType: "blob" // important
    })
      .then(response => {
        if (response != undefined) {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", "file.jpg");
          document.body.appendChild(fileLink);

          fileLink.click();
          resultCode = 200;
        }
      })
      .catch(() => {
        resultCode = 400;
      });

    return resultCode;
  },

  // FOr download image case study
  async downloadImageZipCaseStudy(context, content) {
    var resultCode = null;
    await axios({
      url:
        process.env.VUE_APP_BACKEND_URL + "/api/report-case-study/download-zip",
      params: {
        date: content.date,
        nit: content.nit,
        case_study_id: content.caseStudyId
      },
      method: "GET",
      headers: {
        Authorization: `Bearer ${JwtService.getToken()}`
      },
      responseType: "blob" // important
    })
      .then(response => {
        if (response != undefined) {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", "proctoring.zip");
          document.body.appendChild(fileLink);

          fileLink.click();
          resultCode = 200;
        }
      })
      .catch(() => {
        resultCode = 400;
      });

    return resultCode;
  },

  // For download excel in report case study
  async downloadPdfCaseStudy(context, content) {
    var resultCode = null;
    ApiService.setHeader();
    await axios({
      url:
        process.env.VUE_APP_BACKEND_URL + "/api/report-case-study/download-pdf",
      params: {
        id: content.reportId
      },
      method: "GET",
      headers: {
        Authorization: `Bearer ${JwtService.getToken()}`
      },
      responseType: "blob" // important
    })
      .then(response => {
        if (response != undefined) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "report.pdf");
          document.body.appendChild(link);
          link.click();
          resultCode = 200;
        }
      })
      .catch(() => {
        resultCode = 400;
      });

    return resultCode;
  },

  // For get client psikotest introduction
  async getClientPsikotestIntroductionCaseStudy() {
    if (JwtService.getClientToken()) {
      ApiService.setClientHeader();
      const response = await ApiService.get(
        "api/client-psikotest-introduction/show"
      );
      return response.data;
    }
  },

  // Sending to Capture.co.id
  async getDataToSendCaseStudy(context, content) {
    ApiService.setClientHeader();
    const response = await ApiService.post("api/case-study/send-to-capture", {
      nit: content.nit,
      nama: content.nama,
      email: content.email,
      cid: content.cid,
      uid: content.uid,
      case_study_id: content.case_study_id,
      id_tes: content.id_tes,
      app_url: content.app_url
    });

    return response;
  }
};

const mutations = {
  setCaseStudy(state, caseStudies) {
    state.caseStudies = caseStudies;
  },
  setCaseStudyReports(state, caseStudyReports) {
    state.caseStudyReports = caseStudyReports;
  },
  deleteCaseStudy(state, id) {
    state.caseStudies.data.splice(id, 1);
  },
  duplicateCaseStudy(state, caseStudy) {
    state.caseStudies.data.unshift(caseStudy.data);
  },
  changeStatusCaseStudy(state, id) {
    if (state.caseStudies.data[id].status == "Active") {
      state.caseStudies.data[id].status = "Inactive";
    } else if (state.caseStudies.data[id].status == "Inactive") {
      state.caseStudies.data[id].status = "Active";
    }
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
