import ApiService from "@/common/api.service";
import JwtService from "@/common/jwt.service";
// import router from "@/router";

const state = {
  errors: null,
  message: null,
  interviewSchedules: null,
  interviewVideo: null
};

const getters = {
  getInterviewSchedules(state) {
    return state.interviewSchedules;
  },
  getInterviewVideos(state) {
    return state.interviewVideo;
  }
};

const actions = {
  async getInterviewSchedules(context, credentials) {
    ApiService.setHeader();

    const response = await ApiService.get("api/interview-schedule/index", {
      per_page: credentials.perPage,
      page: credentials.page,
      order_by: credentials.sortField,
      order_direction: credentials.sortOrder,
      search: credentials.search,
      date: null
    });

    context.commit("getInterviewSchedules", response.data);
  },

  async getInterviewVideos(context, credentials) {
    ApiService.setHeader();

    const response = await ApiService.get(
      "api/interview-schedule/index-video",
      {
        per_page: credentials.perPage,
        page: credentials.page,
        order_by: credentials.sortField,
        order_direction: credentials.sortOrder,
        search: credentials.search,
        date: null
      }
    );

    context.commit("getInterviewVideos", response.data);
  },

  // For get detail category show
  async getInterviewSchedule(context, id) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      const response = await ApiService.get(
        "api/interview-schedule/" + id + "/show"
      );
      return response.data;
    }
  },

  // For store category function
  async storeInterviewSchedule(context, interviewContent) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return await ApiService.post("api/interview-schedule/store", {
        interview_date: interviewContent.interviewDate,
        role_play: interviewContent.rolePlay,
        link_zoom: interviewContent.zoomUrl,
        case_study: interviewContent.caseStudy,
        user: interviewContent.user
      });
    }
  },

  // For update category function
  async updateInterviewSchedule(context, interviewContent) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return await ApiService.post(
        "api/interview-schedule/" + interviewContent.id + "/update",
        {
          interview_date: interviewContent.interviewDate,
          role_play: interviewContent.rolePlay,
          link_zoom: interviewContent.zoomUrl,
          case_study: interviewContent.caseStudy,
          user: interviewContent.user
        }
      );
    }
  },

  // For delete category function
  async deleteInterviewSchedule(context, content) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      var resultCode = 200;
      await ApiService.post("api/interview-schedule/" + content.id + "/delete")
        .then(response => {
          if (response.data.meta.statusCode == 200) {
            context.commit("deleteInterviewSchedule", content.index);
          }
        })
        .catch(() => {
          resultCode = 400;
        });
      return resultCode;
    }
  },

  async searchUserLGD(context, content) {
    ApiService.setHeader();
    const response = await ApiService.get("api/interview-schedule/get-user", {
      search: content.search,
      user_list: content.userList
    });
    return response.data;
  },
  async searchCaseStudyLGD(context, content) {
    ApiService.setHeader();
    const response = await ApiService.get(
      "api/interview-schedule/get-case-study",
      {
        search: content.search,
        case_study_list: content.caseStudyList
      }
    );
    return response.data;
  },
  async resendEmail(context, id) {
    ApiService.setHeader();
    var resultCode = 200;
    await ApiService.post("api/interview-schedule/" + id + "/resend").catch(
      () => {
        resultCode = 400;
      }
    );
    return resultCode;
  },
  async uploadVideo(context, content) {
    ApiService.setHeader();
    ApiService.setHeaderMultipartFormData();
    let formData = new FormData();
    formData.append("video", content.video);

    const response = await ApiService.post(
      "api/interview-schedule/upload-video",
      formData
    );
    return response.data;
  }
};

const mutations = {
  getInterviewSchedules(state, interviewSchedules) {
    state.interviewSchedules = interviewSchedules;
  },
  getInterviewVideos(state, interviewVideo) {
    state.interviewVideo = interviewVideo;
  },
  deleteInterviewSchedule(state, id) {
    state.interviewSchedules.data.splice(id, 1);
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
