import ApiService from '@/common/api.service'
import JwtService from '@/common/jwt.service'
// import router from "@/router";

const state = {
  errors: null,
  message: null,
  categories: null,
  category: null,
}

const getters = {}

const actions = {
  // For get all category in index
  async getAnswer(context, content) {
    ApiService.setClientHeader()

    const response = await ApiService.get(
      'api/answer/' + content.assessmentId + '/show',
      {
        nit: content.nit,
      }
    )

    return response.data
  },

  // For iframe access
  async goToIframe(context, content) {
    const response = await ApiService.get('api/iframe', {
      nit: content.nit,
      app_url: content.app_url,
      id_tes: content.id_tes,
      name: content.name,
      email: content.email,
      access_token: content.token,
      uid: content.uid,
      cid: content.cid,
      url: content.url,
      test_id: content.test_id,
    })
    return response
  },

  // For save client access token
  async saveClientAccessToken(context, content) {
    context.commit('setClientAccessToken', content)
    const response = JwtService.getClientToken()
    return response
  },

  // For get client psikotest introduction
  async getClientPsikotestIntroduction() {
    if (JwtService.getClientToken()) {
      ApiService.setClientHeader()
      const response = await ApiService.get(
        'api/client-psikotest-introduction/show'
      )
      return response.data
    }
  },

  // For save time
  async saveTimer(context, content) {
    if (JwtService.getClientToken()) {
      ApiService.setClientHeader()
      const response = await ApiService.post('api/answer/save-timer', {
        assessment_id: content.assessmentId,
        nit: content.nit,
        time: content.time,
      })
      return response.data
    }
  },

  // For auto save data
  async autoSave(context, content) {
    if (JwtService.getClientToken()) {
      ApiService.setClientHeader()
      const response = await ApiService.post('api/answer/store', {
        nit: content.nit,
        assessment_id: content.assessmentId,
        app_url: content.app_url,
        id_tes: content.id_tes,
        answer: content.answer,
        time: content.time,
        active_step: content.activeStep,
        uid: content.uid,
        cid: content.cid,
        email: content.email,
        name: content.name,
        finish: content.finish,
      })
      return response
    }
  },

  // For store hidden screen capture
  async storeHiddenScreenCapture(context, content) {
    ApiService.setClientHeader()
    ApiService.setHeaderMultipartFormData()
    if (content.nit !== undefined) {
      let formData = new FormData()
      formData.append('image', content.image)
      formData.append('nit', content.nit)
      formData.append('assessment_id', content.assessmentId)

      const response = await ApiService.post(
        'api/answer/store-hidden-screen-capture',
        formData
      )
      return response.data
    } else {
      return null
    }
  },

  // For send candidat photo
  async sendCandidatPhoto(context, content) {
    ApiService.setClientHeader()
    ApiService.setHeaderMultipartFormData()
    if (content.nit !== undefined) {
      let formData = new FormData()
      formData.append('image', content.image)
      formData.append('nit', content.nit)
      formData.append('assessment_id', content.assessmentId)

      const response = await ApiService.post(
        'api/answer/candidat-photo',
        formData
      )
      return response.data
    }
  },

  // For store hidden photo
  async storeHiddenPhoto(context, content) {
    ApiService.setClientHeader()
    ApiService.setHeaderMultipartFormData()
    if (content.nit !== undefined) {
      let formData = new FormData()
      formData.append('image', content.image)
      formData.append('nit', content.nit)
      formData.append('assessment_id', content.assessmentId)

      const response = await ApiService.post(
        'api/answer/store-hidden-photo',
        formData
      )
      return response.data
    } else {
      return null
    }
  },

  // For store proctoring record
  async storeProctoringRecord(context, content) {
    ApiService.setClientHeader()
    ApiService.setHeaderMultipartFormData()
    if (content.nit !== undefined) {
      let formData = new FormData()
      formData.append('text', content.text)
      formData.append('action', content.action)
      formData.append('nit', content.nit)
      formData.append('assessment_id', content.assessmentId)

      const response = await ApiService.post(
        'api/answer/store-proctoring-record',
        formData
      )
      return response.data
    } else {
      return null
    }
  },

  // Store If User Leave The Page
  async storeOpenNewTab(context, content) {
    if (JwtService.getClientToken()) {
      ApiService.setClientHeader()
      const response = await ApiService.post(
        'api/answer/store-open-other-tab',
        {
          nit: content.nit,
          assessment_id: content.assessmentId,
          open_other_tab: content.openTab,
        }
      )
      return response
    }
  },

  // Sending to Capture.co.id
  async getFileToSend(context, content) {
    ApiService.setClientHeader()
    const response = await ApiService.post('api/answer/send-to-capture', {
      nit: content.nit,
      nama: content.nama,
      email: content.email,
      cid: content.cid,
      uid: content.uid,
      assessment_id: content.assessment_id,
      id_tes: content.id_tes,
      app_url: content.app_url,
    })

    return response.data
  },
}

const mutations = {
  setClientAccessToken(state, data) {
    JwtService.saveClientToken(data)
  },
}

export default {
  state,
  actions,
  mutations,
  getters,
}
