import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
import Home from '../views/auth/Home.vue'
import store from '@/store'
Vue.use(VueRouter)
Vue.use(VueMeta)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: { requiresAuth: true },
    children: [
      // For User
      {
        path: '/user',
        name: 'user',
        component: () => import('@/views/user/IndexUser.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/user/create',
        name: 'createUser',
        component: () => import('@/views/user/CreateUser.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/user/change-password/:userId',
        name: 'changePassword',
        component: () => import('@/views/user/ChangePassword.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/user/edit/:userId',
        name: 'editUser',
        component: () => import('@/views/user/CreateUser.vue'),
        meta: { requiresAuth: true },
      },

      // For Category
      {
        path: '/category',
        name: 'category',
        component: () => import('@/views/category/IndexCategory.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/category/create',
        name: 'createCategory',
        component: () => import('@/views/category/FormCategory.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/category/edit/:categoryId',
        name: 'editCategory',
        component: () => import('@/views/category/FormCategory.vue'),
        meta: { requiresAuth: true },
      },

      // For Access
      {
        path: '/access',
        name: 'access',
        component: () => import('@/views/v2/access/IndexAccess.vue'),
        meta: { requiresAuth: true },
      },

      {
        path: '/roles',
        name: 'roles',
        component: () => import('@/views/v2/role/IndexRole.vue'),
        meta: { requiresAuth: true },
      },

      // For Assessment
      {
        path: '/assessment',
        name: 'assessment',
        component: () => import('@/views/assessment/IndexAssessment.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/assessment/show/:assessmentId',
        name: 'showAssessment',
        component: () => import('@/views/assessment/ShowAssessment.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/assessment/add-section/:assessmentId',
        name: 'addAssessmentSection',
        component: () => import('@/views/assessment/AssessmentFormSection.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/assessment/show-section/:assessmentId/:assessmentSectionId',
        name: 'showAssessmentSection',
        component: () => import('@/views/assessment/AssessmentShowSection.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/assessment/edit-section/:assessmentId/:assessmentSectionId',
        name: 'editAssessmentSection',
        component: () => import('@/views/assessment/AssessmentFormSection.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/assessment/create',
        name: 'createAssessment',
        component: () => import('@/views/assessment/CreateAssessment.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/assessment/edit/:assessmentId',
        name: 'editAssessment',
        component: () => import('@/views/assessment/CreateAssessment.vue'),
        meta: { requiresAuth: true },
      },

      // For Psikotest Introduction
      {
        path: '/psikotest-introduction',
        name: 'psikotestIntroduction',
        component: () =>
          import('@/views/psikotest-introduction/PsikotestIntroduction.vue'),
        meta: { requiresAuth: true },
      },

      // For Report Assessment
      {
        path: '/report',
        name: 'reportAssessment',
        component: () => import('@/views/report/IndexReport.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/report/show/:reportId',
        name: 'showReportAssessment',
        component: () => import('@/views/report/DetailReport.vue'),
        meta: { requiresAuth: true },
      },

      //For New Report Assessment
      {
        path: '/new-report',
        name: 'newReportAssessment',
        component: () => import('@/views/v2/report/IndexReport.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/new-report/show/:reportId',
        name: 'showNewReportAssessment',
        component: () => import('@/views/v2/report/DetailReport.vue'),
        meta: { requiresAuth: true },
      },

      // For Case Study
      {
        path: '/case-study',
        name: 'caseStudy',
        component: () => import('@/views/case-study/IndexCaseStudy.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/case-study/create',
        name: 'createCaseStudy',
        component: () => import('@/views/case-study/CreateCaseStudy.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/case-study/show/:caseStudyId',
        name: 'showCaseStudy',
        component: () => import('@/views/case-study/ShowCaseStudy.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/case-study/edit/:caseStudyId',
        name: 'editCaseStudy',
        component: () => import('@/views/case-study/CreateCaseStudy.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/case-study/add-section/:caseStudyId',
        name: 'addCaseStudySection',
        component: () =>
          import('@/views/case-study/CreateCaseStudySection.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/case-study/edit-section/:caseStudyId/:caseStudySectionId',
        name: 'editCaseStudySection',
        component: () =>
          import('@/views/case-study/CreateCaseStudySection.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/case-study/show-section/:caseStudyId/:caseStudySectionId',
        name: 'showCaseStudySection',
        component: () => import('@/views/case-study/ShowCaseStudySection.vue'),
        meta: { requiresAuth: true },
      },

      // For Case Study Report
      {
        path: '/case-study-report',
        name: 'reportCaseStudy',
        component: () => import('@/views/case-study/IndexReportCaseStudy.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/case-study-report/show/:reportId',
        name: 'showReportCaseStudy',
        component: () => import('@/views/case-study/DetailReportCaseStudy.vue'),
        meta: { requiresAuth: true },
      },

      // For Interview Schedule
      {
        path: '/interview-schedule',
        name: 'interview',
        component: () =>
          import('@/views/interview-schedule/IndexInterviewSchedule.vue'),
      },
      {
        path: '/interview-schedule/create',
        name: 'createInterviewSchedule',
        component: () =>
          import('@/views/interview-schedule/FormInterviewSchedule.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'interview-schedule/edit/:interviewId',
        name: 'editInterviewSchedule',
        component: () =>
          import('@/views/interview-schedule/FormInterviewSchedule.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'interview-schedule/upload/create',
        name: 'createUploadVideoInterview',
        component: () =>
          import('@/views/interview-schedule/FormUploadVideo.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'interview-schedule/log-history',
        name: 'logInterviewSchedule',
        component: () =>
          import('@/views/interview-schedule/LogInterviewSchedule'),
        meta: { requiresAuth: true },
      },
      {
        path: '/interview-video',
        name: 'interviewVideo',
        component: () =>
          import('@/views/interview-schedule/IndexInterviewVideo.vue'),
      },
      // For New Assessment
      {
        path: '/new-assessment',
        name: 'newAssessment',
        component: () => import('@/views/v2/assessment/IndexAssessment.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/new-assessment/show/:newAssessmentId',
        name: 'showNewAssessment',
        component: () => import('@/views/v2/assessment/ShowAssessment.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/new-assessment/create',
        name: 'createNewAssessment',
        component: () => import('@/views/v2/assessment/FormAssessment.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/new-assessment/edit/:newAssessmentId',
        name: 'editNewAssessment',
        component: () => import('@/views/v2/assessment/FormAssessment.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/new-assessment/show-section/:newAssessmentId/:newSectionId',
        name: 'showNewAssessmentSection',
        component: () => import('@/views/v2/section/ShowSection.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/new-assessment/add-section/:newAssessmentId',
        name: 'createNewAssessmentSection',
        component: () => import('@/views/v2/section/FormSection.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/new-assessment/edit-section/:newAssessmentId/:newSectionId',
        name: 'editNewAssessmentSection',
        component: () => import('@/views/v2/section/FormSection.vue'),
        meta: { requiresAuth: true },
      },

      // For new category
      {
        path: '/new-category',
        name: 'newCategories',
        component: () => import('@/views/v2/category/IndexCategory.vue'),
      },
      {
        path: '/new-category/create',
        name: 'createNewCategories',
        component: () => import('@/views/v2/category/FormCategory.vue'),
      },
      {
        path: '/new-category/edit/:newCategoryId',
        name: 'editNewCategories',
        component: () => import('@/views/v2/category/FormCategory.vue'),
      },

      // For new tag
      {
        path: '/new-tag',
        name: 'newTags',
        component: () => import('@/views/v2/tag/IndexTag.vue'),
      },
      {
        path: '/new-tag/create',
        name: 'createNewTags',
        component: () => import('@/views/v2/tag/FormTag.vue'),
      },
      {
        path: '/new-tag/edit/:newTagId',
        name: 'editNewTags',
        component: () => import('@/views/v2/tag/FormTag.vue'),
      },

      // For new level
      {
        path: '/new-level',
        name: 'newLevels',
        component: () => import('@/views/v2/level/IndexLevel.vue'),
      },
      {
        path: '/new-level/create',
        name: 'createNewLevels',
        component: () => import('@/views/v2/level/FormLevel.vue'),
      },
      {
        path: '/new-level/edit/:newLevelId',
        name: 'editNewLevels',
        component: () => import('@/views/v2/level/FormLevel.vue'),
      },

      // Bank Soal
      {
        path: '/bank-soal',
        name: 'bankSoal',
        component: () => import('@/views/v2/bank-soal/IndexBankSoal.vue'),
      },
    ],
  },

  // For Login
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/auth/Login.vue'),
  },

  // For Forgot Password
  {
    path: '/forgot-password',
    name: 'forgot',
    component: () => import('@/views/auth/Forgot.vue'),
  },

  // For Forgot Password Valid
  {
    path: '/forgot-password/valid',
    name: 'valid',
    component: () => import('@/views/auth/ResetPassword.vue'),
  },

  // For Reset Password Invalid
  {
    path: '/forgot-password/invalid',
    name: 'invalid',
    component: () => import('@/views/auth/Invalid.vue'),
  },
  {
    path: '/preview/assessment/:id',
    name: 'previewAssessment',
    component: () => import('@/views/preview/PreviewAssessment.vue'),
  },
  {
    path: '/case-study/:caseStudyId/:caseStudyCode',
    name: 'caseStudyAnswer',
    component: () => import('@/views/answer-case-study/AnswerCaseStudy.vue'),
  },
  {
    path: '/assessment/:assessmentId/:assessmentCode',
    name: 'answer',
    // component: () => import('@/views/answer/CandidatAnswer.vue'),
    component: () => import('@/views/answer/CandidatAnswer404.vue'),
  },
  {
    path: '/new-assessment/:assessmentCode/:id_tes/:nit/:encryptedData',
    name: 'newAnswer-encrypt',
    component: () => import('@/views/v2/candidat/AnswerBankSoal.vue'),
  },
  {
    path: '/iframe-form',
    name: 'iframeForm',
    component: () => import('@/views/answer/FormIframe.vue'),
  },{
    path: '/testing/procto-hidden-camera',
    name: 'TestingProctoHiddenCamera',
    component: () => import('@/views/testing/procto-hidden-camera.vue'),
  },
  {
    path: '/assessment/page-not-found',
    name: 'pageNotFound',
    component: () => import('@/views/errors/404.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'is-active',
  routes,
  scrollBehavior() {
    window.scrollTo(0, 0)
  },
})

router.beforeEach((to, from, next) => {
  if (
    to.matched.some(record => record.meta.requiresAuth) &&
    !store.getters.isAuthenticated
  ) {
    next({ name: 'login' })
  } else if (
    (to.name == 'login' || to.name == 'register') &&
    store.getters.isAuthenticated
  ) {
    next('/')
  } else {
    next()
  }
})

export default router
