import ApiService from "@/common/api.service";
import JwtService from "@/common/jwt.service";
import axios from "axios";
// const namespaced = true;
const state = {
  errors: null,
  message: null,
  users: null,
  branch: null
};

const getters = {
  getBranch(state) {
    return state.branch;
  }
};

const actions = {
  async getBranch(context, content) {
    ApiService.setHeader();
    const response = await ApiService.get("api/geo-location/index", {
      perPage: content.perPage,
      page: content.page,
      orderBy: content.sortField,
      orderDirection: content.sortOrder,
      search: content.search
    });
    context.commit("setBranch", response.data);
  },
  async getActiveBranch() {
    ApiService.setHeader();
    const response = await ApiService.get("api/geo-location/check-active");
    return response.data;
  },
  async bulkDeleteBranch(context, content) {
    ApiService.setHeader();
    const response = await ApiService.post("api/geo-location/bulk-delete", {
      branch_list: content
    });
    context.commit("setBranch", response.data);
  },
  async importExcel(context, file) {
    ApiService.setHeader();
    ApiService.setHeaderMultipartFormData();
    let formData = new FormData();
    formData.append("file", file);
    await ApiService.post(`api/geo-location/import`, formData);
  },
  async exportGeoLocation() {
    if (JwtService.getToken()) {
      const response = await axios({
        url: process.env.VUE_APP_BACKEND_URL + "/api/geo-location/export",
        method: "GET",
        headers: {
          Authorization: `Bearer ${JwtService.getToken()}`
        },
        responseType: "blob" // important
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "file.xlsx");
      document.body.appendChild(link);
      link.click();
    }
  },
  // async level(context, content) {
  //   ApiService.setHeader();
  //   const response = await ApiService.get("api/geo-location/level", {
  //     type: content.type,
  //     search: content.search,
  //     level: content.level,
  //     type_value: content.type_value,
  //     type_value_2: content.type_value_2
  //   });
  //   return response.data;
  // }
  async level(context, level) {
    ApiService.setHeader();
    const response = await ApiService.get("api/geo-location/level", {
      level: level
    });
    return response.data;
  }
};
const mutations = {
  setBranch(state, branch) {
    state.branch = branch;
  }
};

export default {
  //   namespaced,
  state,
  actions,
  mutations,
  getters
};
