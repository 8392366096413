import ApiService from '@/common/api.service'
import JwtService from '@/common/jwt.service'
import axios from 'axios'

// import Vue from "vue";

const state = {
  errors: null,
  message: null,
  reports: null,
  surveys: null,
  surveyPeriod: null,
}

const getters = {
  getNewReports(state) {
    return state.reports
  },
  getNewGraphActiveSurvey(state) {
    return state.surveys
  },
  getNewSurveyPeriod(state) {
    return state.surveyPeriod
  },
}

const actions = {
  // For get report assessment detail
  async getNewReports(context, content) {
    ApiService.setHeader()
    const response = await ApiService.get('api/v2/report/index', {
      perPage: content.perPage,
      page: content.page,
      orderBy: content.sortField,
      orderDirection: content.sortOrder,
      search: content.search,
      filter: content.filter,
    })
    context.commit('setReports', response.data)
  },

  // For show report assesment
  async getNewReport(context, id) {
    ApiService.setHeader()
    const response = await ApiService.get('api/v2/report/' + id + '/show')
    return response.data
  },

  // For get proctoring in assessment
  async getNewProctoring(context, content) {
    ApiService.setHeader()
    const response = await ApiService.get('api/report/detail-proctoring', {
      nit: content.nit,
      assessment_id: content.assessmentId,
      perPage: content.perPage,
      page: content.page,
      orderBy: content.sortField,
      orderDirection: content.sortOrder,
    })
    return response.data
  },

  // For download excel in report section assessment
  async downloadNewExcel(context, content) {
    var resultCode = null
    ApiService.setHeader()
    await axios({
      url: process.env.VUE_APP_BACKEND_URL + '/api/report/download-excel',
      params: {
        section_id: content.sectionId,
        id: content.reportId,
      },
      method: 'GET',
      headers: {
        Authorization: `Bearer ${JwtService.getToken()}`,
      },
      responseType: 'blob', // important
    })
      .then(response => {
        if (response != undefined) {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'report.xlsx')
          document.body.appendChild(link)
          link.click()
          resultCode = 200
        }
      })
      .catch(() => {
        resultCode = 400
      })

    return resultCode
  },

  async downloadNewExcel2(context, content) {
    var resultCode = null
    ApiService.setHeader()
    await axios({
      url:
        process.env.VUE_APP_BACKEND_URL +
        '/api/v2/report/download-excel' +
        content.excelId,
      params: {
        nit: content.nit,
        nama: content.nama,
        email: content.email,
        cid: content.cid,
        uid: content.uid,
        assessment_id: content.assessment_id,
        id_tes: content.id_tes,
        app_url: content.app_url,
      },
      method: 'GET',
      headers: {
        Authorization: `Bearer ${JwtService.getToken()}`,
      },
      responseType: 'blob', // important
    })
      .then(response => {
        if (response != undefined) {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'report.xlsx')
          document.body.appendChild(link)
          link.click()
          resultCode = 200
        }
      })
      .catch(() => {
        resultCode = 400
      })

    return resultCode
  },

  async downloadExcelResultRaw(context, content) {
    var resultCode = null
    ApiService.setHeader()
    await axios({
      url:
        process.env.VUE_APP_BACKEND_URL +
        '/api/v2/report/download-excel-result-raw',
      params: {
        id: content.id,
        isUsePeriod: content.isUsePeriod,
        startPeriod: content.startPeriod,
        endPeriod: content.endPeriod,
      },
      method: 'GET',
      headers: {
        Authorization: `Bearer ${JwtService.getToken()}`,
      },
      responseType: 'blob', // important
    })
      .then(response => {
        if (response != undefined) {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'result-raw-report.xlsx')
          document.body.appendChild(link)
          link.click()
          resultCode = 200
        }
      })
      .catch(() => {
        resultCode = 400
      })

    return resultCode
  },

  // For download image zip in assessment report
  async downloadNewImageZip(context, content) {
    var resultCode = null
    await axios({
      url: process.env.VUE_APP_BACKEND_URL + '/api/v2/report/download-zip',
      params: {
        date: content.date,
        nit: content.nit,
        assessment_id: content.assessmentId,
      },
      method: 'GET',
      headers: {
        Authorization: `Bearer ${JwtService.getToken()}`,
      },
      responseType: 'blob', // important
    })
      .then(response => {
        if (response != undefined) {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]))
          var fileLink = document.createElement('a')

          fileLink.href = fileURL
          fileLink.setAttribute('download', 'proctoring.zip')
          document.body.appendChild(fileLink)

          fileLink.click()
          resultCode = 200
        }
      })
      .catch(() => {
        resultCode = 400
      })

    return resultCode
  },

  // For download image in assessment report
  async downloadNewImage(context, content) {
    var resultCode = null
    await axios({
      url: process.env.VUE_APP_BACKEND_URL + '/api/report/download-image',
      params: {
        date: content.date,
        image: content.image,
        type: content.type,
        nit: content.nit,
        assessment_id: content.assessmentId,
      },
      method: 'GET',
      headers: {
        Authorization: `Bearer ${JwtService.getToken()}`,
      },
      responseType: 'blob', // important
    })
      .then(response => {
        if (response != undefined) {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]))
          var fileLink = document.createElement('a')

          fileLink.href = fileURL
          fileLink.setAttribute('download', 'file.jpg')
          document.body.appendChild(fileLink)

          fileLink.click()
          resultCode = 200
        }
      })
      .catch(() => {
        resultCode = 400
      })

    return resultCode
  },
  async sendToCapture(context, content) {
    ApiService.setClientHeader()
    const response = await ApiService.post('api/v2/report/send-to-capture', {
      nit: content.nit,
      nama: content.nama,
      email: content.email,
      cid: content.cid,
      uid: content.uid,
      assessment_code: content.assessment_code,
      assessment_id: content.assessment_id,
      id_tes: content.id_tes,
      app_url: content.app_url,
    })

    return response.data
  },
}

const mutations = {
  setGraphActiveSurvey(state, surveys) {
    state.surveys = surveys
  },
  setReports(state, reports) {
    state.reports = reports
  },
  setSurveyPeriod(state, reports) {
    state.surveyPeriod = reports
  },
}

export default {
  //   namespaced,
  state,
  actions,
  mutations,
  getters,
}
