var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"type":"is-light","position":"static","fullwidth":true,"fullheight":this.fullheight,"reduce":_vm.reduce,"open":""}},[_c('div',{class:_vm.$route.name == 'reportAssessment' ? 'p-1' : 'p-2',staticStyle:{"position":"fixed","width":"320px"}},[_c('b-menu',{staticClass:"is-custom-mobile"},[_c('b-menu-list',[(
            this.permissions.findIndex(
              function (e) { return e.access.name == 'Assessment' && e.access.parentId == 0; }
            ) != -1
          )?_c('b-menu-item',{class:{ caret: this.statusAssessment == false },attrs:{"active":_vm.isActiveAssessment,"expanded":_vm.isExpandedAssessment}},[_c('template',{slot:"label"},[_c('span',{staticClass:"menu-position menu-text"},[_c('b-icon',{staticClass:"menu-position-left",attrs:{"pack":"fas","icon":"chart-bar"}}),_c('span',{staticClass:"menu-position-center"},[_vm._v("Assessment")])],1)]),(
              this.permissions.findIndex(
                function (e) { return e.access.url == 'assessment' && e.access.isActive == 1; }
              ) != -1
            )?_c('b-menu-item',{attrs:{"tag":"router-link","to":{ name: "assessment" }}},[_c('template',{slot:"label"},[_c('span',{staticClass:"menu-position menu-text"},[_c('b-icon',{attrs:{"pack":"fas","icon":"clipboard"}}),_c('span',{staticClass:"menu-position-inside"},[_vm._v("Assessment Setup")])],1)])],2):_vm._e(),(
              this.permissions.findIndex(
                function (e) { return e.access.url == 'report' && e.access.isActive == 1; }
              ) != -1
            )?_c('b-menu-item',{attrs:{"tag":"router-link","to":{ name: "reportAssessment" }}},[_c('template',{slot:"label"},[_c('span',{staticClass:"menu-position menu-text"},[_c('b-icon',{attrs:{"pack":"fas","icon":"exclamation-circle"}}),_c('span',{staticClass:"menu-position-inside"},[_vm._v("Assessment Report")])],1)])],2):_vm._e()],2):_vm._e(),(
            this.permissions.findIndex(
              function (e) { return e.access.name == 'Assessment with Bank Soal' &&
                e.access.parentId == 0; }
            ) != -1
          )?_c('b-menu-item',{class:{ caret: this.statusNewAssessment == false },attrs:{"active":_vm.isActiveNewAssessment,"expanded":_vm.isExpandedNewAssessment}},[_c('template',{slot:"label"},[_c('span',{staticClass:"menu-position menu-text"},[_c('b-icon',{staticClass:"menu-position-left",attrs:{"pack":"fas","icon":"chart-bar"}}),_c('span',{staticClass:"menu-position-center"},[_vm._v(" Assessment with "),_c('br'),_vm._v(" Bank Soal ")])],1)]),(
              this.permissions.findIndex(
                function (e) { return e.access.url == 'new-assessment' && e.access.isActive == 1; }
              ) != -1
            )?_c('b-menu-item',{attrs:{"tag":"router-link","to":{ name: "newAssessment" }}},[_c('template',{slot:"label"},[_c('span',{staticClass:"menu-position menu-text"},[_c('b-icon',{attrs:{"pack":"fas","icon":"clipboard"}}),_c('span',{staticClass:"menu-position-inside"},[_vm._v("Assessment Setup")])],1)])],2):_vm._e(),(
              this.permissions.findIndex(
                function (e) { return e.access.url == 'new-report' && e.access.isActive == 1; }
              ) != -1
            )?_c('b-menu-item',{attrs:{"tag":"router-link","to":{ name: "newReportAssessment" }}},[_c('template',{slot:"label"},[_c('span',{staticClass:"menu-position menu-text"},[_c('b-icon',{attrs:{"pack":"fas","icon":"exclamation-circle"}}),_c('span',{staticClass:"menu-position-inside"},[_vm._v("Assessment Report")])],1)])],2):_vm._e()],2):_vm._e(),(
            this.permissions.findIndex(
              function (e) { return e.access.name == 'Case Study' && e.access.parentId == 0; }
            ) != -1
          )?_c('b-menu-item',{class:{ caret: this.statusCaseStudy == false },attrs:{"active":_vm.isActiveCaseStudy,"expanded":_vm.isExpandedCaseStudy}},[_c('template',{slot:"label"},[_c('span',{staticClass:"menu-position menu-text"},[_c('b-icon',{staticClass:"menu-position-left",attrs:{"pack":"fas","icon":"dice-d6"}}),_c('span',{staticClass:"menu-position-center"},[_vm._v("Case Study")])],1)]),(
              this.permissions.findIndex(
                function (e) { return e.access.url == 'case-study' && e.access.isActive == 1; }
              ) != -1
            )?_c('b-menu-item',{attrs:{"tag":"router-link","to":{ name: "caseStudy" }}},[_c('template',{slot:"label"},[_c('span',{staticClass:"menu-position menu-text"},[_c('b-icon',{attrs:{"pack":"fas","icon":"puzzle-piece"}}),_c('span',{staticClass:"menu-position-inside"},[_vm._v("Case Study Setup")])],1)])],2):_vm._e(),(
              this.permissions.findIndex(
                function (e) { return e.access.url == 'case-study-report' &&
                  e.access.isActive == 1; }
              ) != -1
            )?_c('b-menu-item',{attrs:{"tag":"router-link","to":{ name: "reportCaseStudy" }}},[_c('template',{slot:"label"},[_c('span',{staticClass:"menu-position menu-text"},[_c('b-icon',{attrs:{"pack":"fas","icon":"exclamation-circle"}}),_c('span',{staticClass:"menu-position-inside"},[_vm._v("Case Study Report")])],1)])],2):_vm._e()],2):_vm._e(),(
            this.permissions.findIndex(
              function (e) { return e.access.name == 'Bank Soal' && e.access.parentId == 0; }
            ) != -1
          )?_c('b-menu-item',{class:{ caret: this.statusBankSoal == false },attrs:{"active":_vm.isActiveBankSoal,"expanded":_vm.isExpandedBankSoal}},[_c('template',{slot:"label"},[_c('span',{staticClass:"menu-position menu-text"},[_c('b-icon',{staticClass:"menu-position-left",attrs:{"pack":"fas","icon":"bookmark"}}),_c('span',{staticClass:"menu-position-center"},[_vm._v("Bank Soal")])],1)]),(
              this.permissions.findIndex(
                function (e) { return e.access.url == 'bank-soal' && e.access.isActive == 1; }
              ) != -1
            )?_c('b-menu-item',{attrs:{"tag":"router-link","to":{ name: "bankSoal" }}},[_c('template',{slot:"label"},[_c('span',{staticClass:"menu-position menu-text"},[_c('b-icon',{attrs:{"pack":"fas","icon":"bookmark"}}),_c('span',{staticClass:"menu-position-inside"},[_vm._v("Bank Soal Setup")])],1)])],2):_vm._e(),(
              this.permissions.findIndex(
                function (e) { return e.access.url == 'new-category' && e.access.isActive == 1; }
              ) != -1
            )?_c('b-menu-item',{attrs:{"tag":"router-link","to":{ name: "newCategories" }}},[_c('template',{slot:"label"},[_c('span',{staticClass:"menu-position menu-text"},[_c('b-icon',{attrs:{"pack":"fas","icon":"th-large"}}),_c('span',{staticClass:"menu-position-inside"},[_vm._v("Category Bank Soal")])],1)])],2):_vm._e(),(
              this.permissions.findIndex(
                function (e) { return e.access.url == 'new-level' && e.access.isActive == 1; }
              ) != -1
            )?_c('b-menu-item',{attrs:{"tag":"router-link","to":{ name: "newLevels" }}},[_c('template',{slot:"label"},[_c('span',{staticClass:"menu-position menu-text"},[_c('b-icon',{attrs:{"pack":"fas","icon":"layer-group"}}),_c('span',{staticClass:"menu-position-inside"},[_vm._v("Customize Level")])],1)])],2):_vm._e(),(
              this.permissions.findIndex(
                function (e) { return e.access.url == 'new-tag' && e.access.isActive == 1; }
              ) != -1
            )?_c('b-menu-item',{attrs:{"tag":"router-link","to":{ name: "newTags" }}},[_c('template',{slot:"label"},[_c('span',{staticClass:"menu-position menu-text"},[_c('b-icon',{attrs:{"pack":"fas","icon":"tag"}}),_c('span',{staticClass:"menu-position-inside"},[_vm._v("Tag")])],1)])],2):_vm._e()],2):_vm._e(),(
            this.permissions.findIndex(
              function (e) { return e.access.name == 'Setting' && e.access.parentId == 0; }
            ) != -1
          )?_c('b-menu-item',{class:{ caret: this.statusSettingsData == false },attrs:{"active":_vm.isActiveSettingsData,"expanded":_vm.isExpandedSettings}},[_c('template',{slot:"label"},[_c('span',{staticClass:"menu-position menu-text"},[_c('b-icon',{staticClass:"menu-position-left",attrs:{"pack":"fas","icon":"cog"}}),_c('span',{staticClass:"menu-position-center"},[_vm._v("Setting")])],1)]),(
              this.permissions.findIndex(
                function (e) { return e.access.url == 'user' && e.access.isActive == 1; }
              ) != -1
            )?_c('b-menu-item',{attrs:{"tag":"router-link","to":{ name: "user" }}},[_c('template',{slot:"label"},[_c('span',{staticClass:"menu-position menu-text"},[_c('b-icon',{attrs:{"pack":"fas","icon":"users"}}),_c('span',{staticClass:"menu-position-inside"},[_vm._v("Users")])],1)])],2):_vm._e(),(
              this.permissions.findIndex(
                function (e) { return e.access.url == 'roles' && e.access.isActive == 1; }
              ) != -1
            )?_c('b-menu-item',{attrs:{"tag":"router-link","to":{ name: "roles" }}},[_c('template',{slot:"label"},[_c('span',{staticClass:"menu-position menu-text"},[_c('b-icon',{attrs:{"pack":"fas","icon":"users"}}),_c('span',{staticClass:"menu-position-inside"},[_vm._v("Role")])],1)])],2):_vm._e(),(
              this.permissions.findIndex(
                function (e) { return e.access.url == 'access' && e.access.isActive == 1; }
              ) != -1
            )?_c('b-menu-item',{attrs:{"tag":"router-link","to":{ name: "access" }}},[_c('template',{slot:"label"},[_c('span',{staticClass:"menu-position menu-text"},[_c('b-icon',{attrs:{"pack":"fas","icon":"users"}}),_c('span',{staticClass:"menu-position-inside"},[_vm._v("Access")])],1)])],2):_vm._e(),(
              this.permissions.findIndex(
                function (e) { return e.access.url == 'category' && e.access.isActive == 1; }
              ) != -1
            )?_c('b-menu-item',{attrs:{"tag":"router-link","to":{ name: "category" }}},[_c('template',{slot:"label"},[_c('span',{staticClass:"menu-position menu-text"},[_c('b-icon',{attrs:{"pack":"fas","icon":"list"}}),_c('span',{staticClass:"menu-position-inside"},[_vm._v("Category")])],1)])],2):_vm._e(),(
              this.permissions.findIndex(
                function (e) { return e.access.url == 'psikotest-introduction' &&
                  e.access.isActive == 1; }
              ) != -1
            )?_c('b-menu-item',{attrs:{"tag":"router-link","to":{ name: "psikotestIntroduction" }}},[_c('template',{slot:"label"},[_c('span',{staticClass:"menu-position menu-text"},[_c('b-icon',{attrs:{"pack":"fas","icon":"id-badge"}}),_c('span',{staticClass:"menu-position-inside"},[_vm._v(" Psikotest Introduction ")])],1)])],2):_vm._e()],2):_vm._e()],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }