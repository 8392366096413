import ApiService from '@/common/api.service'

const newAssessment = {
  namespaced: true,
  state: () => ({
    errors: null,
    newAssessments: null,
    isLoading: false,
  }),
  getters: {
    // Fot get new assessments
    getNewAssessments(state) {
      return state.newAssessments
    },
  },
  actions: {
    // For new assessments
    fetchAssessments(context, content) {
      return new Promise((resolve, reject) => {
        ApiService.init()
        ApiService.setHeaderMultipartFormData()
        context.commit('setLoading', true)
        ApiService.get(`/api/v2/assessment`, {
          perPage: content.perPage,
          page: content.page,
          sortField: content.sortField,
          sortOrder: content.sortOrder,
          search: content.search,
          status: content.status,
        }).then(
          response => {
            if (response.status === 200) {
              context.commit('setNewAssessments', response.data)
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          error => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    // For fetch assessment by id
    fetchAssessment(context, id) {
      return new Promise((resolve, reject) => {
        ApiService.init()
        ApiService.setHeaderMultipartFormData()
        ApiService.get(`/api/v2/assessment/${id}/show`, {}).then(
          response => {
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
      })
    },

    // For create assessment
    createAssessment(context, form) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)
        ApiService.init()
        ApiService.setHeaderMultipartFormData()
        ApiService.post(`/api/v2/assessment`, form, true).then(
          response => {
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
      })
    },

    // For update assessment
    updateAssessment(context, form) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)
        ApiService.init()
        ApiService.setHeaderMultipartFormData()
        ApiService.put(`/api/v2/assessment/${form.id}/update`, form, true).then(
          response => {
            context.commit('setLoading', false)
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
      })
    },

    // For restore assessment
    restoreAssessment(context, id) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)
        ApiService.init()
        ApiService.setHeaderMultipartFormData()
        ApiService.post(`/api/v2/assessment/${id}/restore`, {}, true).then(
          response => {
            context.commit('setLoading', false)
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
      })
    },

    // For duplicate assessment
    duplicateAssessment(context, id) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)
        ApiService.init()
        ApiService.setHeaderMultipartFormData()
        ApiService.post(`/api/v2/assessment/${id}/duplicate`, {}, true).then(
          response => {
            context.commit('setLoading', false)
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
      })
    },

    // For inactive assessment
    inactiveAssessment(context, id) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)
        ApiService.init()
        ApiService.setHeaderMultipartFormData()
        ApiService.delete(`/api/v2/assessment/${id}/inactive`, {}, true).then(
          response => {
            context.commit('setLoading', false)
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
      })
    },

    // For delete assessment
    deleteAssessment(context, id) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)
        ApiService.init()
        ApiService.setHeaderMultipartFormData()
        ApiService.delete(`/api/v2/assessment/${id}/delete`, {}, true).then(
          response => {
            context.commit('setLoading', false)
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
      })
    },
  },
  mutations: {
    // For set loading
    setLoading(state, data) {
      state.isLoading = data
    },

    // For set new assessments
    setNewAssessments(state, data) {
      state.newAssessments = data
    },
  },
}

export default newAssessment
