import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "./jwt.service";
import camelcaseKeys from "camelcase-keys";
const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL;
    Vue.axios.defaults.headers.post["Content-Type"] =
      "application/x-www-form-urlencoded";
  },
  setHeader() {
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
  },
  setClientHeader() {
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getClientToken()}`;
  },
  setHeaderMultipartFormData() {
    Vue.axios.defaults.headers.post["Content-Type"] = "multipart/form-data";
  },
  query(resource) {
    return Vue.axios.get(resource);
  },
  get(resource, params) {
    return Vue.axios.get(`${resource}`, {
      params: params,
      transformResponse: [
        data => {
          return camelcaseKeys(JSON.parse(data), { deep: true });
        }
      ]
    });
  },
  post(resource, params) {
    return Vue.axios.post(`${resource}`, params, {
      transformResponse: [
        data => {
          if (data) {
            return camelcaseKeys(JSON.parse(data), { deep: true });
          }
        }
      ]
    });
  },
  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params);
  },
  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },
  delete(resource) {
    return Vue.axios.delete(resource);
  }
};
export default ApiService;
