import ApiService from "@/common/api.service";
import JwtService from "@/common/jwt.service";
// import router from "@/router";

const state = {
  errors: null,
  message: null,
  assessment: null,
  assessments: null,
  sections: null,
  surveyBranches: null
};

const getters = {
  getAssessment(state) {
    return state.assessment;
  },
  getAssessments(state) {
    return state.assessments;
  }
};

const actions = {
  async previewAssessments(context, id) {
    ApiService.setHeader();
    const response = await ApiService.get("api/assessment/" + id + "/preview");
    return response.data;
  },
  // For get assessment data in index
  async getAssessments(context, content) {
    ApiService.setHeader();
    const response = await ApiService.get("api/assessment/index", {
      perPage: content.perPage,
      page: content.page,
      orderBy: content.sortField,
      orderDirection: content.sortOrder,
      search: content.search,
      status: content.status
    });
    context.commit("setAssessments", response.data);
  },

  // For show assessment function
  async getAssessment(context, id) {
    ApiService.setHeader();
    const response = await ApiService.get("api/assessment/" + id + "/show");
    return response.data;
  },

  // For create assessment function
  async createAssessment(context, content) {
    ApiService.setHeader();
    const response = await ApiService.post("api/assessment/store", {
      // Information
      pageTitle: content.pageTitle,
      pageDescription: content.pageDescription,
      category: content.category,
      status: content.status,

      // Thank You Page
      thankYouPageTitle: content.thankYouPageTitle,
      thankYouPageDescription: content.thankYouPageDescription,

      screenCaptureTime: content.screenCaptureTime,
      screenShotTime: content.screenShotTime,

      isUseRules: content.isUseRules,

      start_date: content.startDate,
      end_date: content.endDate
    });
    return response;
  },

  async editAssessment(context, content) {
    ApiService.setHeader();
    const response = await ApiService.post(
      "api/assessment/" + content.id + "/edit",
      {
        // Information
        pageTitle: content.pageTitle,
        pageDescription: content.pageDescription,
        category: content.category,
        status: content.status,

        // Thank You Page
        thankYouPageTitle: content.thankYouPageTitle,
        thankYouPageDescription: content.thankYouPageDescription,

        screenCaptureTime: content.screenCaptureTime,
        screenShotTime: content.screenShotTime,

        isUseRules: content.isUseRules,

        start_date: content.startDate,
        end_date: content.endDate
      }
    );
    return response;
  },

  async getAssessmentSection(context, id) {
    ApiService.setHeader();
    const response = await ApiService.get(
      "api/assessment/" + id + "/show-section"
    );
    return response.data;
  },

  // For save assessment section
  async saveAssessmentSection(context, content) {
    ApiService.setHeader();

    // For save section that use question assessment section
    ApiService.setHeaderMultipartFormData();
    let sendImageQuestionSectionIntroductionTest = false;
    let formDataQuestionSectionIntroductionTest = new FormData();
    let questionFileSectionIntroductionTest = [];
    content.sectionIntroductionTest.map((sectionIntroductionTest, index1) => {
      if (sectionIntroductionTest.isUseQuestion == true) {
        if (
          sectionIntroductionTest.questionType != "text" &&
          sectionIntroductionTest.questionType != "youtube" &&
          sectionIntroductionTest.questionType != "free_text" &&
          sectionIntroductionTest.questionType != "text_youtube"
        ) {
          sendImageQuestionSectionIntroductionTest = true;
          sectionIntroductionTest.questionFile.map((questionFile, index2) => {
            formDataQuestionSectionIntroductionTest.append(
              "groupId[" + index1 + "][" + index2 + "]",
              sectionIntroductionTest.groupId
            );
            formDataQuestionSectionIntroductionTest.append(
              "fileQuestion[" + index1 + "][" + index2 + "]",
              questionFile.file
            );
            formDataQuestionSectionIntroductionTest.append(
              "fileNumber[" + index1 + "][" + index2 + "]",
              questionFile.fileNumber
            );
            formDataQuestionSectionIntroductionTest.append(
              "fileId[" + index1 + "][" + index2 + "]",
              questionFile.fileNumber
            );
            formDataQuestionSectionIntroductionTest.append(
              "assessmentType[" + index1 + "][" + index2 + "]",
              "Assessment Introduction Test"
            );
          });
        }
      }
    });
    if (sendImageQuestionSectionIntroductionTest) {
      formDataQuestionSectionIntroductionTest.append(
        "assessmentId",
        content.assessmentId
      );
      formDataQuestionSectionIntroductionTest.append("type", "create");
      formDataQuestionSectionIntroductionTest.append(
        "typeTest",
        "Introduction"
      );
      formDataQuestionSectionIntroductionTest.append(
        "sectionId",
        content.sectionId
      );

      const responseQuestionSectionIntroductionTest = await ApiService.post(
        "api/assessment/upload-file-section-create",
        formDataQuestionSectionIntroductionTest
      );

      questionFileSectionIntroductionTest =
        responseQuestionSectionIntroductionTest.data;
    }

    // For Assessment Introduction Test Question File Data
    ApiService.setHeaderMultipartFormData();
    let sendImageQuestionAssessmentIntroductionTest = false;
    let formDataQuestionAssessmentIntroductionTest = new FormData();
    let questionFileDataAssessmentIntroductionTest = [];
    content.sectionIntroductionTest.map((sectionIntroductionTest, index1) => {
      sectionIntroductionTest.assessment.map(
        (assessmentIntroductionTest, index2) => {
          if (
            assessmentIntroductionTest.questionType != "text" &&
            assessmentIntroductionTest.questionType != "youtube" &&
            assessmentIntroductionTest.questionType != "free_text" &&
            assessmentIntroductionTest.questionType != "text_youtube"
          ) {
            sendImageQuestionAssessmentIntroductionTest = true;
            assessmentIntroductionTest.questionFile.map(
              (questionFile, index3) => {
                formDataQuestionAssessmentIntroductionTest.append(
                  "groupId[" + index1 + "][" + index2 + "][" + index3 + "]",
                  sectionIntroductionTest.groupId
                );
                formDataQuestionAssessmentIntroductionTest.append(
                  "questionId[" + index1 + "][" + index2 + "][" + index3 + "]",
                  assessmentIntroductionTest.questionId
                );
                formDataQuestionAssessmentIntroductionTest.append(
                  "fileQuestion[" +
                    index1 +
                    "][" +
                    index2 +
                    "][" +
                    index3 +
                    "]",
                  questionFile.file
                );
                formDataQuestionAssessmentIntroductionTest.append(
                  "fileNumber[" + index1 + "][" + index2 + "][" + index3 + "]",
                  questionFile.fileNumber
                );
                formDataQuestionAssessmentIntroductionTest.append(
                  "fileId[" + index1 + "][" + index2 + "][" + index3 + "]",
                  questionFile.fileId
                );
                formDataQuestionAssessmentIntroductionTest.append(
                  "assessmentType[" +
                    index1 +
                    "][" +
                    index2 +
                    "][" +
                    index3 +
                    "]",
                  "Assessment Introduction Test"
                );
              }
            );
          }
        }
      );
    });
    if (sendImageQuestionAssessmentIntroductionTest) {
      formDataQuestionAssessmentIntroductionTest.append(
        "assessmentId",
        content.assessmentId
      );
      formDataQuestionAssessmentIntroductionTest.append("type", "create");
      formDataQuestionAssessmentIntroductionTest.append(
        "typeTest",
        "introduction"
      );
      formDataQuestionAssessmentIntroductionTest.append(
        "sectionId",
        content.sectionId
      );
      const responseQuestionFileIntroductionTest = await ApiService.post(
        "api/assessment/upload-file-question-create",
        formDataQuestionAssessmentIntroductionTest
      );

      questionFileDataAssessmentIntroductionTest =
        responseQuestionFileIntroductionTest.data;
    }

    // For Assessment Introduction Test Option File Data
    let optionFileDataAssessmentIntroductionTest = [];
    if (content.questionType == "Multiple Choice") {
      ApiService.setHeaderMultipartFormData();
      let formDataOptionAssessmentIntroductionTest = new FormData();
      let sendImageOptionAssessmentIntroductionTest = false;
      content.sectionIntroductionTest.map((sectionIntroduction, index1) => {
        sectionIntroduction.assessment.map((assessmentIntroduction, index2) => {
          assessmentIntroduction.option.map((option, index3) => {
            if (option.type != "text") {
              sendImageOptionAssessmentIntroductionTest = true;
              formDataOptionAssessmentIntroductionTest.append(
                "groupId[" + index1 + "][" + index2 + "][" + index3 + "]",
                sectionIntroduction.groupId
              );
              formDataOptionAssessmentIntroductionTest.append(
                "questionId[" + index1 + "][" + index2 + "][" + index3 + "]",
                assessmentIntroduction.questionId
              );
              formDataOptionAssessmentIntroductionTest.append(
                "optionId[" + index1 + "][" + index2 + "][" + index3 + "]",
                option.optionId
              );
              formDataOptionAssessmentIntroductionTest.append(
                "optionNumber[" + index1 + "][" + index2 + "][" + index3 + "]",
                option.optionNumber
              );
              formDataOptionAssessmentIntroductionTest.append(
                "fileOption[" + index1 + "][" + index2 + "][" + index3 + "]",
                option.file
              );
              formDataOptionAssessmentIntroductionTest.append(
                "assessmentType[" +
                  index1 +
                  "][" +
                  index2 +
                  "][" +
                  index3 +
                  "]",
                "Assessment Introduction Test"
              );
            }
          });
        });
      });
      if (sendImageOptionAssessmentIntroductionTest) {
        formDataOptionAssessmentIntroductionTest.append(
          "assessmentId",
          content.assessmentId
        );
        formDataOptionAssessmentIntroductionTest.append("type", "create");
        formDataOptionAssessmentIntroductionTest.append(
          "typeTest",
          "introduction"
        );
        formDataOptionAssessmentIntroductionTest.append(
          "sectionId",
          content.sectionId
        );
        const responseOptionFileIntroductionTest = await ApiService.post(
          "api/assessment/upload-file-option-create",
          formDataOptionAssessmentIntroductionTest
        );

        optionFileDataAssessmentIntroductionTest =
          responseOptionFileIntroductionTest.data;
      }
    }

    // For save section that use question in assessment question
    ApiService.setHeaderMultipartFormData();
    let sendImageQuestionSectionQuestionTest = false;
    let formDataQuestionSectionQuestionTest = new FormData();
    let questionFileSectionQuestionTest = [];
    content.sectionQuestionTest.map((sectionQuestionTest, index1) => {
      if (sectionQuestionTest.isUseQuestion == true) {
        if (
          sectionQuestionTest.questionType != "text" &&
          sectionQuestionTest.questionType != "youtube" &&
          sectionQuestionTest.questionType != "free_text" &&
          sectionQuestionTest.questionType != "text_youtube"
        ) {
          sendImageQuestionSectionQuestionTest = true;
          sectionQuestionTest.questionFile.map((questionFile, index2) => {
            formDataQuestionSectionQuestionTest.append(
              "groupId[" + index1 + "][" + index2 + "]",
              sectionQuestionTest.groupId
            );
            formDataQuestionSectionQuestionTest.append(
              "fileQuestion[" + index1 + "][" + index2 + "]",
              questionFile.file
            );
            formDataQuestionSectionQuestionTest.append(
              "fileNumber[" + index1 + "][" + index2 + "]",
              questionFile.fileNumber
            );
            formDataQuestionSectionQuestionTest.append(
              "fileId[" + index1 + "][" + index2 + "]",
              questionFile.fileNumber
            );
            formDataQuestionSectionQuestionTest.append(
              "assessmentType[" + index1 + "][" + index2 + "]",
              "Assessment Question Test"
            );
          });
        }
      }
    });
    if (sendImageQuestionSectionQuestionTest) {
      formDataQuestionSectionQuestionTest.append(
        "assessmentId",
        content.assessmentId
      );
      formDataQuestionSectionQuestionTest.append("type", "create");
      formDataQuestionSectionQuestionTest.append("typeTest", "question");
      formDataQuestionSectionQuestionTest.append(
        "sectionId",
        content.sectionId
      );

      const responseQuestionSectionQuestionTest = await ApiService.post(
        "api/assessment/upload-file-section-create",
        formDataQuestionSectionQuestionTest
      );

      questionFileSectionQuestionTest =
        responseQuestionSectionQuestionTest.data;
    }

    // For Assessment Question Test Question File Data
    ApiService.setHeaderMultipartFormData();
    let sendImageQuestionAssessmentQuestionTest = false;
    let formDataQuestionAssessmentQuestionTest = new FormData();
    let questionFileDataAssessmentQuestionTest = [];
    content.sectionQuestionTest.map((sectionQuestionTest, index1) => {
      sectionQuestionTest.assessment.map((assessmentQuestionTest, index2) => {
        if (
          assessmentQuestionTest.questionType != "text" &&
          assessmentQuestionTest.questionType != "youtube" &&
          assessmentQuestionTest.questionType != "free_text" &&
          assessmentQuestionTest.questionType != "text_youtube"
        ) {
          sendImageQuestionAssessmentQuestionTest = true;
          assessmentQuestionTest.questionFile.map((questionFile, index3) => {
            formDataQuestionAssessmentQuestionTest.append(
              "groupId[" + index1 + "][" + index2 + "][" + index3 + "]",
              sectionQuestionTest.groupId
            );
            formDataQuestionAssessmentQuestionTest.append(
              "questionId[" + index1 + "][" + index2 + "][" + index3 + "]",
              assessmentQuestionTest.questionId
            );
            formDataQuestionAssessmentQuestionTest.append(
              "fileNumber[" + index1 + "][" + index2 + "][" + index3 + "]",
              questionFile.fileNumber
            );
            formDataQuestionAssessmentQuestionTest.append(
              "fileId[" + index1 + "][" + index2 + "][" + index3 + "]",
              questionFile.fileId
            );
            formDataQuestionAssessmentQuestionTest.append(
              "fileQuestion[" + index1 + "][" + index2 + "][" + index3 + "]",
              questionFile.file
            );
            formDataQuestionAssessmentQuestionTest.append(
              "assessmentType[" + index1 + "][" + index2 + "][" + index3 + "]",
              "Assessment Question Test"
            );
          });
        }
      });
    });
    if (sendImageQuestionAssessmentQuestionTest) {
      formDataQuestionAssessmentQuestionTest.append(
        "assessmentId",
        content.assessmentId
      );
      formDataQuestionAssessmentQuestionTest.append("type", "create");
      formDataQuestionAssessmentQuestionTest.append(
        "sectionId",
        content.sectionId
      );
      formDataQuestionAssessmentQuestionTest.append("typeTest", "question");
      const responseQuestionFileQuestionTest = await ApiService.post(
        "api/assessment/upload-file-question-create",
        formDataQuestionAssessmentQuestionTest
      );

      questionFileDataAssessmentQuestionTest =
        responseQuestionFileQuestionTest.data;
    }

    // For Assessment Question Test Option File Data
    let optionFileDataAssessmentQuestionTest = [];
    if (content.questionType == "Multiple Choice") {
      ApiService.setHeaderMultipartFormData();
      let formDataOptionAssessmentQuestionTest = new FormData();
      let sendImageOptionAssessmentQuestionTest = false;
      content.sectionQuestionTest.map((sectionQuestion, index1) => {
        sectionQuestion.assessment.map((assessmentQuestion, index2) => {
          assessmentQuestion.option.map((option, index3) => {
            if (option.type != "text") {
              sendImageOptionAssessmentQuestionTest = true;
              formDataOptionAssessmentQuestionTest.append(
                "groupId[" + index1 + "][" + index2 + "][" + index3 + "]",
                sectionQuestion.groupId
              );
              formDataOptionAssessmentQuestionTest.append(
                "questionId[" + index1 + "][" + index2 + "][" + index3 + "]",
                assessmentQuestion.questionId
              );
              formDataOptionAssessmentQuestionTest.append(
                "optionId[" + index1 + "][" + index2 + "][" + index3 + "]",
                option.optionId
              );
              formDataOptionAssessmentQuestionTest.append(
                "optionNumber[" + index1 + "][" + index2 + "][" + index3 + "]",
                option.optionNumber
              );
              formDataOptionAssessmentQuestionTest.append(
                "fileOption[" + index1 + "][" + index2 + "][" + index3 + "]",
                option.file
              );
              formDataOptionAssessmentQuestionTest.append(
                "assessmentType[" +
                  index1 +
                  "][" +
                  index2 +
                  "][" +
                  index3 +
                  "]",
                "Assessment Question Test"
              );
            }
          });
        });
      });
      if (sendImageOptionAssessmentQuestionTest) {
        formDataOptionAssessmentQuestionTest.append(
          "assessmentId",
          content.assessmentId
        );
        formDataOptionAssessmentQuestionTest.append("type", "create");
        formDataOptionAssessmentQuestionTest.append(
          "sectionId",
          content.sectionId
        );
        formDataOptionAssessmentQuestionTest.append("typeTest", "question");
        const responseOptionFileQuestionTest = await ApiService.post(
          "api/assessment/upload-file-option-create",
          formDataOptionAssessmentQuestionTest
        );

        optionFileDataAssessmentQuestionTest =
          responseOptionFileQuestionTest.data;
      }
    }

    const response = await ApiService.post(
      "api/assessment/store-assessment-section",
      {
        // For Assessment Id
        assessmentId: content.assessmentId,

        // For Introduction Section
        sectionId: content.sectionId,
        sectionName: content.sectionName,
        sectionDescription: content.sectionDescription,

        // For Rules Section
        rulesName: content.rulesName,
        time: content.time,
        isUseTime: content.isUseTime,
        questionType: content.questionType,
        multipleChoiceType: content.multipleChoiceType,
        checkboxTypeAnswer: content.checkboxTypeAnswer,
        checkboxMinAnswer: content.checkboxMinAnswer,
        checkboxMaxAnswer: content.checkboxMaxAnswer,
        rankingTypeAnswer: content.rankingTypeAnswer,
        rankingMinAnswer: content.rankingMinAnswer,
        rankingMaxAnswer: content.rankingMaxAnswer,
        randomType: content.randomType,
        randomQuestionGroupNumber: content.randomQuestionGroupNumber,
        randomQuestionNumber: content.randomQuestionNumber,
        questionToDisplay: content.questionToDisplay,
        rankingPointCategory: content.rankingPointCategory,
        typePoint: content.typePoint,

        // For Assessment Introduction Test Section
        assessmentIntroductionTest: content.sectionIntroductionTest,
        assessmentIntroductionTestSectionFile: questionFileSectionIntroductionTest,
        assessmentIntroductionTestQuestionFile: questionFileDataAssessmentIntroductionTest,
        assessmentIntroductionTestOptionFile: optionFileDataAssessmentIntroductionTest,
        introduction: content.introduction,

        // For Assessment Question Test Section
        assessmentQuestionTest: content.sectionQuestionTest,
        assessmentQuestionTestSectionFile: questionFileSectionQuestionTest,
        assessmentQuestionTestQuestionFile: questionFileDataAssessmentQuestionTest,
        assessmentQuestionTestOptionFile: optionFileDataAssessmentQuestionTest
      }
    );
    return response.data;
  },

  async updateAssessmentSection(context, content) {
    ApiService.setHeader();

    // For file data question in assessment introduction test
    let fileDataQuestionAssessmentIntroductionTest = [];
    if (content.assessmentIntroductionListEditedPhotoQuestion.length > 0) {
      let formDataQuestionAssessmentIntroductionTest = new FormData();
      content.assessmentIntroductionListEditedPhotoQuestion.map(
        (listPhoto, index) => {
          formDataQuestionAssessmentIntroductionTest.append(
            "type[" + index + "]",
            listPhoto.type
          );
          formDataQuestionAssessmentIntroductionTest.append(
            "questionId[" + index + "]",
            listPhoto.questionId
          );
          formDataQuestionAssessmentIntroductionTest.append(
            "fileData[" + index + "]",
            listPhoto.fileData
          );
          formDataQuestionAssessmentIntroductionTest.append(
            "filePath[" + index + "]",
            listPhoto.filePath
          );
          formDataQuestionAssessmentIntroductionTest.append(
            "fileId[" + index + "]",
            listPhoto.fileId
          );
          formDataQuestionAssessmentIntroductionTest.append(
            "fileNumber[" + index + "]",
            listPhoto.fileNumber
          );
        }
      );
      formDataQuestionAssessmentIntroductionTest.append(
        "typeTest",
        "introduction"
      );
      formDataQuestionAssessmentIntroductionTest.append(
        "assessmentId",
        content.assessmentId
      );
      formDataQuestionAssessmentIntroductionTest.append(
        "sectionId",
        content.sectionId
      );

      const responseQuestionFileIntroductionTest = await ApiService.post(
        "api/assessment/upload-file-question-update",
        formDataQuestionAssessmentIntroductionTest
      );

      fileDataQuestionAssessmentIntroductionTest =
        responseQuestionFileIntroductionTest.data;
    }

    // For file data option in assessment introduction test
    let fileDataOptionAssessmentIntroductionTest = [];
    if (content.assessmentIntroductionListEditedPhotoOption.length > 0) {
      let formDataOptionAssessmentIntroductionTest = new FormData();
      content.assessmentIntroductionListEditedPhotoOption.map(
        (listPhoto, index) => {
          formDataOptionAssessmentIntroductionTest.append(
            "type[" + index + "]",
            listPhoto.type
          );
          formDataOptionAssessmentIntroductionTest.append(
            "questionId[" + index + "]",
            listPhoto.questionId
          );
          formDataOptionAssessmentIntroductionTest.append(
            "fileData[" + index + "]",
            listPhoto.fileData
          );
          formDataOptionAssessmentIntroductionTest.append(
            "filePath[" + index + "]",
            listPhoto.filePath
          );
          formDataOptionAssessmentIntroductionTest.append(
            "optionNumber[" + index + "]",
            listPhoto.optionNumber
          );
          formDataOptionAssessmentIntroductionTest.append(
            "optionId[" + index + "]",
            listPhoto.optionId
          );
        }
      );
      formDataOptionAssessmentIntroductionTest.append(
        "typeTest",
        "introduction"
      );
      formDataOptionAssessmentIntroductionTest.append(
        "assessmentId",
        content.assessmentId
      );
      formDataOptionAssessmentIntroductionTest.append(
        "sectionId",
        content.sectionId
      );

      const responseOptionFileIntroductionTest = await ApiService.post(
        "api/assessment/upload-file-option-update",
        formDataOptionAssessmentIntroductionTest
      );

      fileDataOptionAssessmentIntroductionTest =
        responseOptionFileIntroductionTest.data;
    }

    // For file data question in assessment question test
    let fileDataQuestionAssessmentQuestionTest = [];
    if (content.assessmentQuestionListEditedPhotoQuestion.length > 0) {
      let formDataQuestionAssessmentQuestionTest = new FormData();
      content.assessmentQuestionListEditedPhotoQuestion.map(
        (listPhoto, index) => {
          formDataQuestionAssessmentQuestionTest.append(
            "type[" + index + "]",
            listPhoto.type
          );
          formDataQuestionAssessmentQuestionTest.append(
            "questionId[" + index + "]",
            listPhoto.questionId
          );
          formDataQuestionAssessmentQuestionTest.append(
            "fileData[" + index + "]",
            listPhoto.fileData
          );
          formDataQuestionAssessmentQuestionTest.append(
            "filePath[" + index + "]",
            listPhoto.filePath
          );
          formDataQuestionAssessmentQuestionTest.append(
            "fileNumber[" + index + "]",
            listPhoto.fileNumber
          );
          formDataQuestionAssessmentQuestionTest.append(
            "fileId[" + index + "]",
            listPhoto.fileId
          );
        }
      );
      formDataQuestionAssessmentQuestionTest.append("typeTest", "question");
      formDataQuestionAssessmentQuestionTest.append(
        "assessmentId",
        content.assessmentId
      );
      formDataQuestionAssessmentQuestionTest.append(
        "sectionId",
        content.sectionId
      );

      const responseQuestionFileQuestionTest = await ApiService.post(
        "api/assessment/upload-file-question-update",
        formDataQuestionAssessmentQuestionTest
      );

      fileDataQuestionAssessmentQuestionTest =
        responseQuestionFileQuestionTest.data;
    }

    // For file data option in assessment question test
    let fileDataOptionAssessmentQuestionTest = [];
    if (content.assessmentQuestionListEditedPhotoOption.length > 0) {
      let formDataOptionAssessmentQuestionTest = new FormData();
      content.assessmentQuestionListEditedPhotoOption.map(
        (listPhoto, index) => {
          formDataOptionAssessmentQuestionTest.append(
            "type[" + index + "]",
            listPhoto.type
          );
          formDataOptionAssessmentQuestionTest.append(
            "questionId[" + index + "]",
            listPhoto.questionId
          );
          formDataOptionAssessmentQuestionTest.append(
            "fileData[" + index + "]",
            listPhoto.fileData
          );
          formDataOptionAssessmentQuestionTest.append(
            "filePath[" + index + "]",
            listPhoto.filePath
          );
          formDataOptionAssessmentQuestionTest.append(
            "optionNumber[" + index + "]",
            listPhoto.optionNumber
          );
          formDataOptionAssessmentQuestionTest.append(
            "optionId[" + index + "]",
            listPhoto.optionId
          );
        }
      );
      formDataOptionAssessmentQuestionTest.append("typeTest", "question");
      formDataOptionAssessmentQuestionTest.append(
        "assessmentId",
        content.assessmentId
      );
      formDataOptionAssessmentQuestionTest.append(
        "sectionId",
        content.sectionId
      );

      const responseOptionFileQuestionTest = await ApiService.post(
        "api/assessment/upload-file-option-update",
        formDataOptionAssessmentQuestionTest
      );

      fileDataOptionAssessmentQuestionTest =
        responseOptionFileQuestionTest.data;
    }

    const response = await ApiService.post(
      "api/assessment/" + content.id + "/update-assessment-section",
      {
        // For Assessment Id
        assessmentId: content.assessmentId,

        // For Introduction Section
        sectionName: content.sectionName,
        sectionDescription: content.sectionDescription,

        // For Rules Section
        rulesName: content.rulesName,
        time: content.time,
        isUseTime: content.isUseTime,
        questionType: content.questionType,
        multipleChoiceType: content.multipleChoiceType,
        checkboxTypeAnswer: content.checkboxTypeAnswer,
        checkboxMinAnswer: content.checkboxMinAnswer,
        checkboxMaxAnswer: content.checkboxMaxAnswer,
        rankingTypeAnswer: content.rankingTypeAnswer,
        rankingMinAnswer: content.rankingMinAnswer,
        rankingMaxAnswer: content.rankingMaxAnswer,
        randomType: content.randomType,
        randomQuestionGroupNumber: content.randomQuestionGroupNumber,
        randomQuestionNumber: content.randomQuestionNumber,
        questionToDisplay: content.questionToDisplay,
        rankingPointCategory: content.rankingPointCategory,
        typePoint: content.typePoint,

        // For Assessment Introduction Test Section
        assessmentIntroductionTest: content.sectionIntroductionTest,
        assessmentIntroductionTestQuestionFile: fileDataQuestionAssessmentIntroductionTest,
        assessmentIntroductionTestOptionFile: fileDataOptionAssessmentIntroductionTest,
        assessmentIntroductionTestDeletedSection:
          content.assessmentIntroductionListDeletedSection,
        assessmentIntroductionTestDeletedQuestion:
          content.assessmentIntroductionListDeletedQuestion,
        introduction: content.introduction,

        // For Assessment Question Test Section
        assessmentQuestionTest: content.sectionQuestionTest,
        assessmentQuestionTestQuestionFile: fileDataQuestionAssessmentQuestionTest,
        assessmentQuestionTestDeletedSection:
          content.assessmentQuestionListDeletedSection,
        assessmentQuestionTestDeletedQuestion:
          content.assessmentQuestionListDeletedQuestion,
        assessmentQuestionTestOptionFile: fileDataOptionAssessmentQuestionTest
      }
    );

    return response.data;
  },

  // For archive assessment
  async archiveAssessment(context, content) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      const response = await ApiService.post(
        "api/assessment/" + content.id + "/archive"
      );
      if (response.status == 200) {
        if (content.status != "All") {
          context.commit("deleteAssessment", content.index);
          if (this.getters.getAssessments.total > -1) {
            this.getters.getAssessments.total -= 1;
            this.getters.getAssessments.to -= 1;
          }
          if (this.getters.getAssessments.total == 0) {
            this.getters.getAssessments.from = 0;
          }
        } else {
          context.commit("changeStatusAssessment", content.index);
        }
      }
    }
  },

  // For restore assessment archive
  async restoreAssessment(context, content) {
    ApiService.setHeader();
    const response = await ApiService.post(
      "api/assessment/" + content.id + "/restore"
    );
    if (response.status == 200) {
      if (content.status != "All") {
        context.commit("deleteAssessment", content.index);
        if (this.getters.getAssessments.total > -1) {
          this.getters.getAssessments.total -= 1;
          this.getters.getAssessments.to -= 1;
        }
        if (this.getters.getAssessments.total == 0) {
          this.getters.getAssessments.from = 0;
        }
      } else {
        context.commit("restoreStatusAssessment", content.index);
      }
    }
  },

  // For permanently delete assessment
  async permanentDeleteAssessment(context, content) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      const response = await ApiService.post(
        "api/assessment/" + content.id + "/delete"
      );
      if (response.status == 200) {
        context.commit("deleteAssessment", content.index);
        this.getters.getAssessments.total -= 1;
      }
    }
  },

  // For delete assessment section
  async deleteAssessmentSection(context, content) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      const response = await ApiService.post(
        "api/assessment/" + content.id + "/delete-section"
      );
      if (response.status == 200) {
        return response;
      }
    }
  },

  // For search category function
  async searchCategory(context, content) {
    ApiService.setHeader();
    const response = await ApiService.get("api/assessment/get-category", {
      search: content
    });
    return response.data;
  },

  // For duplicate assessment
  async duplicateAssessment(context, content) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      const response = await ApiService.post(
        "api/assessment/" + content.id + "/duplicate-assessment"
      );
      if (response.status == 200) {
        context.commit("duplicateAssessment", response.data);
        this.getters.getAssessments.total += 1;
      }
    }
  },

  // For duplicate section
  async duplicateAssessmentSection(context, content) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      const response = await ApiService.post(
        "api/assessment/" + content.id + "/duplicate-section"
      );
      return response;
    }
  },

  // FOr store hidden photo
  async storeHiddenPhoto(context, content) {
    ApiService.setClientHeader();
    ApiService.setHeaderMultipartFormData();
    if (content.userId !== undefined) {
      let formData = new FormData();
      formData.append("image", content.image);
      formData.append("user_id", content.userId);
      formData.append("assessment_id", content.assessmentId);

      const response = await ApiService.post(
        "api/assessment/store-hidden-photo",
        formData
      );
      return response.data;
    } else {
      return null;
    }
  }
};

const mutations = {
  setAssessment(state, assessment) {
    state.assessment = assessment;
  },
  setAssessments(state, assessments) {
    state.assessments = assessments;
  },
  deleteAssessment(state, id) {
    state.assessments.data.splice(id, 1);
  },
  duplicateAssessment(state, survey) {
    state.assessments.data.unshift(survey.data);
  },
  changeStatusAssessment(state, id) {
    state.assessments.data[id].status = "Inactive";
  },
  restoreStatusAssessment(state, id) {
    state.assessments.data[id].status = "Active";
  }
};

export default {
  //   namespaced,
  state,
  actions,
  mutations,
  getters
};
