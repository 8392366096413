import ApiService from "@/common/api.service";
import JwtService from "@/common/jwt.service";
import axios from "axios";

// import Vue from "vue";

const state = {
  errors: null,
  message: null,
  reports: null,
  surveys: null,
  surveyPeriod: null
};

const getters = {
  getReports(state) {
    return state.reports;
  },
  getGraphActiveSurvey(state) {
    return state.surveys;
  },
  getSurveyPeriod(state) {
    return state.surveyPeriod;
  }
};

const actions = {
  // For get report assessment detail
  async getReports(context, content) {
    ApiService.setHeader();
    const response = await ApiService.get("api/report/index", {
      perPage: content.perPage,
      page: content.page,
      orderBy: content.sortField,
      orderDirection: content.sortOrder,
      search: content.search,
      filter: content.filter
    });
    context.commit("setReports", response.data);
  },

  // For show report assesment
  async getReport(context, id) {
    ApiService.setHeader();
    const response = await ApiService.get("api/report/" + id + "/show");
    return response.data;
  },

  // For get proctoring in assessment
  async getProctoring(context, content) {
    ApiService.setHeader();
    const response = await ApiService.get("api/report/detail-proctoring", {
      nit: content.nit,
      assessment_id: content.assessmentId,
      perPage: content.perPage,
      page: content.page,
      orderBy: content.sortField,
      orderDirection: content.sortOrder
    });
    return response.data;
  },

  // For download excel in report section assessment
  async downloadExcel(context, content) {
    var resultCode = null;
    ApiService.setHeader();
    await axios({
      url: process.env.VUE_APP_BACKEND_URL + "/api/report/download-excel",
      params: {
        section_id: content.sectionId,
        id: content.reportId
      },
      method: "GET",
      headers: {
        Authorization: `Bearer ${JwtService.getToken()}`
      },
      responseType: "blob" // important
    })
      .then(response => {
        if (response != undefined) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "report.xlsx");
          document.body.appendChild(link);
          link.click();
          resultCode = 200;
        }
      })
      .catch(() => {
        resultCode = 400;
      });

    return resultCode;
  },

  async downloadExcel2(context, content) {
    var resultCode = null
    ApiService.setHeader()
    await axios({
      url: process.env.VUE_APP_BACKEND_URL + '/api/download-excel2',
      params: {
        nit: content.nit,
        nama: content.nama,
        email: content.email,
        cid: content.cid,
        uid: content.uid,
        assessment_id: content.assessment_id,
        id_tes: content.id_tes,
        app_url: content.app_url,
      },
      method: 'GET',
      headers: {
        Authorization: `Bearer ${JwtService.getToken()}`,
      },
      responseType: 'blob', // important
    })
      .then(response => {
        if (response != undefined) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a')
          link.href = url;
          link.setAttribute('download', 'report.xlsx')
          document.body.appendChild(link)
          link.click()
          resultCode = 200
        }
      })
      .catch(() => {
        resultCode = 400
      })

    return resultCode
  },

  // For download image zip in assessment report
  async downloadImageZip(context, content) {
    var resultCode = null;
    await axios({
      url: process.env.VUE_APP_BACKEND_URL + "/api/report/download-zip",
      params: {
        date: content.date,
        nit: content.nit,
        assessment_id: content.assessmentId
      },
      method: "GET",
      headers: {
        Authorization: `Bearer ${JwtService.getToken()}`
      },
      responseType: "blob" // important
    })
      .then(response => {
        if (response != undefined) {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", "proctoring.zip");
          document.body.appendChild(fileLink);

          fileLink.click();
          resultCode = 200;
        }
      })
      .catch(() => {
        resultCode = 400;
      });

    return resultCode;
  },

  // For download image in assessment report
  async downloadImage(context, content) {
    var resultCode = null;
    await axios({
      url: process.env.VUE_APP_BACKEND_URL + "/api/report/download-image",
      params: {
        date: content.date,
        image: content.image,
        type: content.type,
        nit: content.nit,
        assessment_id: content.assessmentId
      },
      method: "GET",
      headers: {
        Authorization: `Bearer ${JwtService.getToken()}`
      },
      responseType: "blob" // important
    })
      .then(response => {
        if (response != undefined) {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", "file.jpg");
          document.body.appendChild(fileLink);

          fileLink.click();
          resultCode = 200;
        }
      })
      .catch(() => {
        resultCode = 400;
      });

    return resultCode;
  }
};

const mutations = {
  setGraphActiveSurvey(state, surveys) {
    state.surveys = surveys;
  },
  setReports(state, reports) {
    state.reports = reports;
  },
  setSurveyPeriod(state, reports) {
    state.surveyPeriod = reports;
  }
};

export default {
  //   namespaced,
  state,
  actions,
  mutations,
  getters
};
