import ApiService from "@/common/api.service";
import JwtService from "@/common/jwt.service";
// import router from "@/router";

const state = {
  errors: null,
  message: null,
  categories: null,
  category: null
};

const getters = {
  getCategories(state) {
    return state.categories;
  },
  getCategory(state) {
    return state.category;
  }
};

const actions = {
  // For get all category in index
  async getCategories(context, credentials) {
    ApiService.setHeader();

    const response = await ApiService.get("api/category/index", {
      perPage: credentials.perPage,
      page: credentials.page,
      orderBy: credentials.sortField,
      orderDirection: credentials.sortOrder,
      search: credentials.search
    });

    context.commit("setCategories", response.data);
  },

  // For get detail category show
  async getCategory(context, id) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      const response = await ApiService.get("api/category/" + id + "/show");
      return response.data;
    }
  },

  // For store category function
  async storeCategory(context, category) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return await ApiService.post("api/category/store", {
        name: category.name
      });
    }
  },

  // For update category function
  async updateCategory(context, category) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return await ApiService.post(
        "api/category/" + category.id + "/update",
        category
      );
    }
  },

  // For delete category function
  async deleteCategory(context, credentials) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      const response = await ApiService.post(
        "api/category/" + credentials.id + "/destroy"
      );
      if (response.status == 200) {
        context.commit("deleteCategory", credentials.index);
      }
    }
  }
};

const mutations = {
  setCategories(state, categories) {
    state.categories = categories;
  },
  setCategory(state, category) {
    state.category = category;
  },
  deleteCategory(state, id) {
    state.categories.data.splice(id, 1);
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
